import { useNavigate } from 'react-router-dom';
import { Table } from 'antd';
import { usePackages } from '../../System/Global/DataHooks/usePackages';

export const PackageList = () => {

    const { packages } = usePackages()

    const navigate = useNavigate();
    
    let cols = [
        {
            title: 'Name',
            render: item => `${item.pk_name}`,
            key: 'pr_name',
        },
        {
            title: 'Pages',
            render: item => `${item.pk_pages}`,
            key: 'pk_pages',
        },
        {
            title: 'Monthly Price',
            render: item => `${item.pk_price}`,
            key: 'pk_price',
        },
        
    ]


    const onRowClick = (record) => {
        return {
            onClick: event => {

                navigate('/packages/view/' + record.pk_id)
            },
        };
    };

    
   
    return (
        packages &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Packages</h1>
                </div>
            </div>
            <Table 
                dataSource={packages} 
                rowKey="pk_id" 
                columns={cols} 
                pagination={false}
                onRow={onRowClick}
            />
        </div>
    )
}

export default PackageList;