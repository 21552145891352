import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Row, Select, Table } from 'antd';
import dayjs from 'dayjs';
import { useInvoicing } from '../../System/Global/DataHooks/useInvoicing';
import { useCampaigns } from '../../System/Global/DataHooks/useCampaigns';
import { useEffect, useState } from 'react';

export const AddInvoice = () => {

    const { invid } = useParams();
    const navigate = useNavigate();
    const { saveInvoice, fetchInvoice } = useInvoicing();
    const { findCampaigns } = useCampaigns();
    const [ campaigns, setCampaigns ] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ start, setStart ] = useState(dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'))
    const [ end, setEnd ] = useState(dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'))

    useEffect( () => {

        ( async () => {

            if (invid) {

                let _invoice = await fetchInvoice(invid)

                setCampaigns(_invoice.inv_campaigns)
                setStart(dayjs(_invoice.inv_start).format('YYYY-MM-DD'))
                setEnd(dayjs(_invoice.inv_end).format('YYYY-MM-DD'))
                setTotal(_invoice.inv_total)

                

            }

        })()

    }, [invid])



    const searchCampaigns = async () => {

        let campaigns = await findCampaigns(start, end)

        let total = 0;
        for await (let c of campaigns) {
            total += +c.price
        }
        total = parseFloat(total).toFixed(2)

        setTotal(total)
        setCampaigns(campaigns)

    }

    const addInvoice = async () => {

        await saveInvoice({
            inv_start:      start,
            inv_end:        end,
            inv_campaigns:  campaigns,
            inv_total:      total,
        })

        navigate('/billing')

    }
    
    let cols = [
        {
            title: 'Campaign',
            render: item => `${item.cm_name}`,
            key: 'name',
        },
        {
            title: 'Package',
            render: item => `${item.pk_name}`,
            key: 'package',
        },
        {
            title: 'Price',
            render: item => `£${item.price}`,
            key: 'price',
        },
    ]


    return (
        <>
        <h1>{ invid ? "Invoice" : "New Invoice"}</h1>
            <Form layout="vertical">
                
                        
                        <Card style={{marginBottom: 30}}>
                        <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Start date" rules={[
                            {
                                required: true,
                                message: 'Please enter a start date!',
                            }]}>
                            <Input disabled={invid} type="date" value={start} onChange={(e) => setStart(e.target.value)}/>
                        </Form.Item>
                    </Col>
                    
                    <Col span={6}>
                        <Form.Item label="End date" value={end} rules={[
                            {
                                required: true,
                                message: 'Please enter an end date!',
                            }]}>
                            <Input disabled={invid} type="date" value={end} onChange={(e) => setEnd(e.target.value)}/>
                        </Form.Item>
                    

                    </Col> 
                    { !invid &&
                    <Col span={4}>
                        <Button onClick={() => searchCampaigns() } style={{marginTop: 40}}>Find campaigns</Button>
                    </Col> }
                    <Col span={4}>
                        { campaigns.length > 0 ? <h2>Campaigns: {campaigns.length}</h2> : ""}
                        { campaigns.length > 0 ? <h2>Total: £{total}</h2> : ""}
                    </Col>
                    { !invid &&
                    <Col span={4}>
                        <Button onClick={() => addInvoice() } style={{marginTop: 40}}>Save invoice</Button>
                    </Col> }

                </Row>
                </Card>

                <Card style={{marginTop: 30}}>
                    <h3>Campaigns</h3>
                    <Table 
                        dataSource={campaigns}
                        rowKey="cm_id" 
                        columns={cols} 
                        pagination={false}
                        // onRow={onRowClick}
                    />
                </Card>
            </Form>
        </>
    )
}

export default AddInvoice;