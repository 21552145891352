import { Route, Routes } from 'react-router-dom';
import { FiBox, FiPlus } from 'react-icons/fi';
import ProductList from '../../Components/Products/ProductList';
import AddProduct from '../../Components/Products/AddProduct';

export const ProductMenu = () => ( {
     key: "6",
     link: "/products/list",
     label: 'Products',
     icon: <FiBox/>,
     innerMenu: [
          {
               key: "6.1",
               title: "Products"
          },
          {
               key: "6.2",
               link: "/products/add",
               label: 'New Product',
               icon: <FiPlus/>,
          },
          {
               key: "6.3",
               link: "/products/list",
               label: 'Products',
               icon: <FiBox/>,
          }
     ]
     
})

export const productRoutes = () => (
     <Routes> 
          <Route path="products/list" element={<ProductList />} />
          <Route path="products/add" element={<AddProduct />} />
          <Route path="products/view/:prid" element={<AddProduct />} />
     </Routes>
)