import { Button, Card, Col, Form, Input, Row, Select } from "antd"
import { useUsers } from "../../System/Global/DataHooks/useUsers"
import { useNavigate } from "react-router-dom"

const AddUser = () => {

    const [ form ] = Form.useForm(); 
    const { saveUser } = useUsers();
    const navigate = useNavigate();

    const createUser = async (values) => {

        let us_id = await saveUser(values);
        navigate('/users/view/' + us_id)
    }

    return (
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Add new user</h1>
                </div>
            </div>

            
            <Row gutter={16}>
                    
                <Col span={12}>
                    <Form onFinish={createUser} form={form} layout="vertical" initialValues={{us_role: "User", us_status: "Active"}}>
                        <Card style={{marginBottom: 30}}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="us_first_name" label="First name" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a first name!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="us_last_name" label="Last name" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a last name!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item name="us_title" label="Job title" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a job title!',
                                }]}>
                                <Input />
                            </Form.Item>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="us_email" label="Email address" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter an email address!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="us_phone" label="Phone" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a phone number!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="us_role" label="Role" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a role for the user!',
                                        }]}>
                                        <Select>
                                            <Select.Option value="User">User</Select.Option>
                                            <Select.Option value="Admin">Admin</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="us_status" label="Status" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a status for the user!',
                                        }]}>
                                        <Select>
                                            <Select.Option value="Active">Active</Select.Option>
                                            <Select.Option value="Inactive">Inactive</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                
                            </Row>

                            <Form.Item>
                                <div style={{textAlign: 'right'}}>
                                    <Button style={{marginRight: 5}} onClick={() => navigate(-1)}>Cancel</Button>
                                    <Button htmlType="submit" type="primary">Save</Button>
                                </div>
                            </Form.Item>
                            
                        </Card>
                    </Form>
                </Col>

            </Row>
        </div>
    )
}

export default AddUser