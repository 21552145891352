import { Route, Routes } from 'react-router-dom';
import { FiList, FiPlus, FiUser } from 'react-icons/fi';
import TasksList from '../../Components/Campaigns/Tasks/TasksList';
import AdminTasksList from '../../Components/Tasks/AdminTasksList';
import ViewTask from '../../Components/Tasks/ViewTask';

export const TasksMenu = () => ( {
     key: "8",
     link: "/tasks/list/mine",
     label: 'Admin Tasks',
     icon: <FiList/>,
     innerMenu: [
          {
               key: "8.1",
               title: "Admin Tasks"
          },
          {
               key: "8.2",
               link: "/tasks/add",
               label: 'Manual Task',
               icon: <FiPlus/>,
          },
          {
               key: "8.3",
               link: "/tasks/list/mine",
               label: 'My Tasks',
               icon: <FiUser/>,
          },
          {
               key: "8.4",
               link: "/tasks/list/all",
               label: 'All Tasks',
               icon: <FiList/>,
          }
     ]
     
})

export const tasksRoutes = () => (
     <Routes> 
          <Route path="tasks/list" element={<TasksList />} />
          <Route path="tasks/view/:tkId" element={<ViewTask />} />
          <Route path="tasks/list/:assigned" element={<AdminTasksList />} />
          <Route path="tasks/list/:assigned/:status" element={<AdminTasksList />} />
     </Routes>
)