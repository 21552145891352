import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Input, Row, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useCampaigns } from '../../System/Global/DataHooks/useCampaigns';
import { useGlobal } from '../../System/Global/GlobalProvider';
import SiteConfig from '../../System/SiteConfig';
import { get, remove, save } from '../../System/Api/API';


export const CampaignList = () => {

    const { setCampaign, UserData } = useGlobal();
    const { fetchCampaigns } = useCampaigns();
    const [ campaigns, setCampaigns ] = useState([])
    const [ filteredCampaigns, setFilteredCampaigns ] = useState([])
    const navigate = useNavigate();
    const [ searchText, setSearchText ] = useState("")
    const [ totals, setTotals ] = useState({})
    
    useEffect( () => {

        ( async () => {

                
            
                let _campaigns = [];
                _campaigns = await fetchCampaigns()

                setTotals({
                    under:  _campaigns.filter((c) => c.cm_status == "Live" && c.cm_performance === "Under Performing").length,
                    average:  _campaigns.filter((c) => c.cm_status == "Live" && c.cm_performance === "Average Performance").length,
                    over:  _campaigns.filter((c) => c.cm_status == "Live" && c.cm_performance === "Over Performing").length
                })

                setCampaigns(_campaigns)
                setFilteredCampaigns(_campaigns)

                processCancelled()
                
                
        })()

        return () => {
            setCampaigns([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect( () => {

        if (campaigns.length > 0) {

            processCampaigns(campaigns);

        }

    }, [campaigns])

    const createLog = async (campaign, type) => {

        const obj = {lg_cm_id: campaign.cm_id, lg_type: type}
        console.log('Log object', obj)
        await save('log_entries', obj)
    }

    const updateCampaign = async (record, campaign) => {

        if (campaign.cm_id) {

            console.log('Update campaign', campaign, 'Updated')
            
            let { tlwebsite, target_url,website_url, company_name, category_name, contact_email } = record

            let newData = {
                cm_name: company_name,
                cm_url: website_url,
                cm_industry: category_name,
                cm_website: tlwebsite,
                cm_target_pages: JSON.stringify([{url: target_url}]),
                cm_email: contact_email,
            }

            await save('campaigns', newData, {cm_id: campaign.cm_id})
            
            await createLog(campaign, 'Updated')
        }
        
    }

    const cancelCampaign = async (campaign) => {

        if (campaign.cm_id && campaign.cm_status !== "Cancelled") {

            console.log('New cancellation', campaign, 'Cancelled')

            // Delete the tasks

            await remove('tasks', {tk_cm_id: campaign.cm_id, 'tk_due >=': dayjs().add(1, 'month').format('YYYY-MM-DD')})

            // Update the camapaign cm_status to "Cancelled"
            // Update cm_cancelled to the current date

            await save('campaigns', {cm_status: "Cancelled", cm_cancelled: dayjs().format('YYYY-MM-DD')}, {cm_id: campaign.cm_id})
            
            await createLog(campaign, 'Cancelled')
        }
        
    }

    const newCampaign = async (record) => {

        // Create a campaign and get the cm_id

        console.log('New campaign', record, 'New')
            
        let { Product, tlwebsite, target_url,website_url, company_name, category_name, contact_email, account_order_no, seolocation, account_ref_no } = record

        const getPackage = (name) => {

            if (name === "SEO") { return "350566e0-dae0-11ee-b9b8-9fd0b194d652" }
            if (name === "SEO Starter") { return "9d7d2d20-dae0-11ee-b9b8-9fd0b194d652" }
            if (name === "SEO Intermediate") { return "9d7d2d20-dae0-11ee-b9b8-9fd0b194d6522" }
            if (name === "SEO Premium") { return "9d7d2d20-dae0-11ee-b9b8-9fd0b194d6523" }
            if (name === "SEO Multi-page") { return "9d7d2d20-dae0-11ee-b9b8-9fd0b194d6524" }

        }

        let newData = {
            cm_sap_id: account_ref_no,
            cm_service_id: record.service_id,
            cm_order_no: account_order_no,
            cm_name: company_name,
            cm_url: website_url,
            cm_pk_id: getPackage(Product),
            cm_target_pages: JSON.stringify([{url: target_url}]),
            cm_industry: category_name,
            cm_status: "Live",
            cm_website: tlwebsite,
            cm_location: seolocation,
            cm_email: contact_email,
        }

        console.log('New campaign data', newData)

        let cm_id = await save('campaigns', newData)
        
        await createLog({cm_id, ...newData}, 'New')
    }

    const processCampaigns = async () => {

        let notify = await get('notify', {processed: '0'})

        for await (let n of notify) {

            let record_type = n.data.record_type;
            let campaign = campaigns.find((c) => +c.cm_service_id === +n.data.service_id)

            if (record_type === "N") {
                await newCampaign(n.data)
                await save('notify', {processed: '1'}, {id: n.id})
            }
            if (record_type === "C") {
                await cancelCampaign(campaign)
                await save('notify', {processed: '1'}, {id: n.id})
            }
            if (record_type === "U") {
                await updateCampaign(n.data, campaign)
                await save('notify', {processed: '1'}, {id: n.id})
            }

        }

        

    }

    const processCancelled = async () => {

        const _cancelled = await get('getCancelled')



        for await ( let c of _cancelled) {

            let cm_service_id = c.service_id;

            let campaign = await get('campaigns', {cm_service_id});

            if (campaign.length > 0) {

                let status = campaign[0].cm_status;

                if (status === "Live") {
                    console.log('Need to cancel:', campaign[0])

                    const cm_id = campaign[0].cm_id;

                    // Delete the tasks

                    await remove('tasks', {tk_cm_id: cm_id, 'tk_due >=': dayjs().add(1, 'month').format('YYYY-MM-DD')})

                    // Update the camapaign cm_status to "Cancelled"
                    // Update cm_cancelled to the current date

                    await save('campaigns', {cm_status: "Cancelled", cm_cancelled: dayjs().format('YYYY-MM-DD')}, {cm_id: cm_id})

                }

            }
            
        }

    }

    useEffect( () => {

        if (campaigns.length > 0) {

            setFilteredCampaigns(campaigns.filter((c) => c.cm_name.toLowerCase().includes(searchText.toLowerCase())))
        }

    }, [searchText])

    let cols = [
        {
            title: 'Name',
            render: item => `${item.cm_name}`,
            key: 'cm_name',
        },
        {
            title: 'Service Id',
            render: item => `${item.cm_service_id}`,
            key: 'cm_service_id',
        },
        {
            title: 'Performance',
            render: item => `${item.cm_performance}`,
            key: 'performance',
        },
        {
            title: 'URL',
            render: item => `${item.cm_url}`,
            key: 'cm_url',
        },
        {
            title: 'Created',
            key: 'date',
            render: item => dayjs(item.cm_created).format('DD/MM/YYYY')
        },
        {
            title: 'SAP ID',
            render: item => `${item.cm_sap_id}`,
            key: 'cm_sap_id',
        },
        {
            title: 'Package',
            render: item => `${item.pk_name}`,
            key: 'cm_pk_id',
        },
        {
            title: 'Status',
            render: item => `${item.cm_status}`,
            key: 'status',
        },
        
    ]

    const onRowClick = (record) => {
        return {
            onClick: event => {

                // select campign

                localStorage.setItem(`${SiteConfig.cookieName}-cm_id`, record.cm_id);
                setCampaign(record);

                if (UserData.us_role === "Admin") {

                    navigate('/campaign/admin')

                } else {

                    if (record.cm_onboarded) {
                        navigate('/lighthouse/list')
                    } else {
                        navigate('/campaign/view')
                    }

                }

                
                
            },
        };
    };

    return (
        campaigns &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Campaigns</h1>
                </div>
                <div>
                    <Button onClick={() => navigate('/campaigns/add')} type="primary">New campaign</Button>
                </div>
            </div>
            <div style={{marginBottom: 30}}>
                <Row gutter={30}>
                    <Col xs={8}>
                        <Card>
                            <h3>Under performing</h3>
                            <h1>{totals.under}</h1>
                        </Card>
                    </Col>
                    <Col xs={8}>
                        <Card>
                            <h3>Average performance</h3>
                            <h1>{totals.average}</h1>
                        </Card>
                    </Col>
                    <Col xs={8}>
                        <Card>
                            <h3>Over performing</h3>
                            <h1>{totals.over}</h1>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div>
                <Card>
                <Input value={searchText} onChange={(v) => setSearchText(v.target.value) } placeholder="Search by campaign name"/>
                </Card>
                <br/>
            </div>
            <Tabs>
                <Tabs.TabPane key="1" tab="Active">
                    <Table 
                        dataSource={filteredCampaigns.filter((c) => c.cm_status === "Live")} 
                        rowKey="cm_id" 
                        columns={cols} 
                        pagination={false}
                        onRow={onRowClick}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab="Cancelled">
                    <Table 
                        dataSource={filteredCampaigns.filter((c) => c.cm_status === "Cancelled")} 
                        rowKey="cm_id" 
                        columns={cols} 
                        pagination={false}
                        onRow={onRowClick}
                    />
                </Tabs.TabPane>
            </Tabs>
            
        </div>
    )
}

export default CampaignList;