// unescapeArray.js

export function unescapeArray(arr) {
     return arr.map(item => {
          if (typeof item === 'string') {
               return unescape(item);
          } else if (Array.isArray(item)) {
               return unescapeArray(item);
          } else if (typeof item === 'object' && item !== null) {
               const unescapedItem = {};
               for (const [key, value] of Object.entries(item)) {
                    unescapedItem[key] = typeof value === 'string' ? unescape(value) : unescapeArray([value])[0];
               }
               return unescapedItem;
          } else {
               return item;
          }
     });
}

export function escapeNested(item) {
     if (typeof item === 'string') {
       // Escape the string
       return escape(item);
     } else if (Array.isArray(item)) {
       // Recursively escape strings within the array
       return item.map(escapeNested);
     } else if (typeof item === 'object' && item !== null) {
       // Recursively escape strings within each property of the object
       const escapedItem = {};
       for (const [key, value] of Object.entries(item)) {
         escapedItem[key] = escapeNested(value);
       }
       return escapedItem;
     } else {
       // Return the item if it is neither a string, an array, nor an object
       return item;
     }
}

export function unescapeNested(item) {
     if (typeof item === 'string') {
       return unescape(item);
     } else if (Array.isArray(item)) {
       return item.map(unescapeNested);
     } else if (typeof item === 'object' && item !== null) {
       const unescapedItem = {};
       for (const [key, value] of Object.entries(item)) {
         unescapedItem[key] = unescapeNested(value);
       }
       return unescapedItem;
     } else {
       return item;
     }
}

export function stringifyArraysInObject(obj) {
     // Make sure the input is indeed an object
     if (typeof obj !== 'object' || obj === null) {
       throw new Error('Input must be an object.');
     }
   
     // Clone the object to avoid mutating the original
     const newObj = { ...obj };
   
     // Iterate over the object's keys
     for (const key in newObj) {
       if (Array.isArray(newObj[key])) {
         // If the value is an array, stringify it
         newObj[key] = JSON.stringify(newObj[key]);
       }
       // If it's a nested object, recursively call the function
       else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
         newObj[key] = stringifyArraysInObject(newObj[key]);
       }
     }
   
     return newObj;
}

export function parseStringifiedArrays(item) {
     if (typeof item === 'string') {
       try {
         // Check if the string is a valid JSON array
         const parsed = JSON.parse(item);
         if (Array.isArray(parsed)) {
           // If it's an array, recursively parse any stringified arrays within it
           return parsed.map(parseStringifiedArrays);
         }
       } catch (e) {
         // If JSON.parse throws an error, it's not a JSON string, return the original string
         return item;
       }
       // Return the original string if it's not a JSON array
       return item;
     } else if (Array.isArray(item)) {
       // If it's an array, recursively parse any stringified arrays within it
       return item.map(parseStringifiedArrays);
     } else if (typeof item === 'object' && item !== null) {
       const parsedObject = {};
       for (const [key, value] of Object.entries(item)) {
         // Recursively parse any stringified arrays in object properties
         parsedObject[key] = parseStringifiedArrays(value);
       }
       return parsedObject;
     } else {
       // Return the item as is if it's neither a string nor an object/array
       return item;
     }
}

