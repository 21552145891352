import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, DatePicker, Input, Modal, Popconfirm, Select, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useTasks } from '../../System/Global/DataHooks/useTasks';
import { useUsers } from '../../System/Global/DataHooks/useUsers';
import SiteConfig from '../../System/SiteConfig';
import { useProducts } from '../../System/Global/DataHooks/useProducts';

export const AdminTasksList = () => {

    const { fetchAdminTasks, saveTask } = useTasks();
    const [ tasks, setTasks ] = useState([])
    const { assigned, status } = useParams();
    const navigate = useNavigate();
    const [ selectedRows, setSelectedRows ] = useState([])
    const { fetchAdminUsers } = useUsers();
    const { products } = useProducts();
    const [ users, setUsers ] = useState([]);
    const [ showModal, setShowModal ] = useState(false)
    const [ userSearch, setUserSearch ] = useState("")
    const [ activeTab, setActiveTab ] = useState("1")
    const [ productFilter, setProductFilter ] = useState("all")
    const [ dates, setDates ] = useState(
        {
            from: dayjs(localStorage.getItem(`${SiteConfig}-fromdate`) || dayjs().startOf('month')).format('YYYY-MM-DD'),
            to: dayjs(localStorage.getItem(`${SiteConfig}-todate`) || dayjs().add('2', 'months').endOf('month')).format('YYYY-MM-DD')
        }
    )
    
    useEffect( () => {

        if (assigned) {

            getTasks()

            if (status === "outstanding") {
                setActiveTab("2")
            }
            if (status === "completed") {
                setActiveTab("3")
            }

            if (assigned === "mine") {
                setActiveTab("2")
            }

            
        }

        return () => {
            setTasks([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assigned, dates])

    const getTasks = async () => {

        let _tasks = [];
        _tasks = await fetchAdminTasks(assigned, dates.from, dates.to)

        
        setUsers(await fetchAdminUsers())
        
        setTasks(_tasks)

    }

    const changeDateRange = async (e) => {

        let fromDate = dayjs(e[0]).format('YYYY-MM-DD')
        let toDate = dayjs(e[1]).format('YYYY-MM-DD')

        localStorage.setItem(`${SiteConfig}-fromdate`, fromDate);
        localStorage.setItem(`${SiteConfig}-todate`, toDate);

        setDates({from: fromDate, to: toDate});

    }

    

    let cols = [
        {
            title: 'No',
            render: (item, x, i) => `${i+1}`,
            key: 'no',
        },
        {
            title: 'Campaign',
            render: item => `${item.cm_name}`,
            key: 'cm_name',
        },
        {
            title: 'Service Id',
            render: item => `${item.cm_service_id}`,
            key: 'cm_service_id',
        },
        {
            title: 'Product Name',
            render: item => `${item.pr_name}`,
            key: 'pr_name',
        },
        {
            title: 'Minutes',
            render: item => `${item.pr_minutes}`,
            key: 'pr_minutes',
        },
        {
            title: 'Target',
            render: item => item.pr_level === "Page" ? <div>{item.tk_target_name} ({item.tk_target_url})</div> : <div>Site</div>,
            key: 'pr_level',
        },
        {
            title: 'Month',
            key: 'month',
            render: item => item.tk_month
        },
        {
            title: 'Due',
            key: 'date',
            render: item => {
             
                let color = +dayjs(item.tk_due).format('DD') % 2 === 0;

                return (
                    <div style={{padding: 5, backgroundColor: color ? "#f1f1f1" : ""}}>{dayjs(item.tk_due).format('DD/MM/YYYY')}</div>
                )
            }
        },
        {
            title: 'Assigned',
            key: 'assigned',
            render: item => item.tk_us_id ? `${item.us_first_name} ${item.us_last_name}` : "",
        },
        {
            title: 'Automated',
            key: 'lh',
            render: item => {
                
                if (item.pr_name === "Blog Content") {
                    return item.tk_result ? "Yes" : ""
                }
                if (item.pr_name.toLowerCase().includes("optimisation")) {
                    return item.lh_id ? "Yes" : ""
                }

                return "";
            }
        }
        
    ]

    const assignRows = async (us_id, us_first_name, us_last_name) => {

        const updatedTasks = [...tasks];

        for await (let row of selectedRows) {

            await saveTask({tk_us_id: us_id}, row.tk_id)

            // need to update the tk_us_id and set the tasks again

            const taskIndex = tasks.findIndex(task => task.tk_id === row.tk_id);

            if (taskIndex !== -1) {
                // Copy the tasks array
                

                // Update the tk_us_id of the specific task
                updatedTasks[taskIndex] = { ...updatedTasks[taskIndex], tk_us_id: us_id, us_first_name, us_last_name };

                // Update the state
                
            }


        }

        setTasks(updatedTasks);


        setShowModal(false)

        setSelectedRows([])
        // let batchKey = await createSubbieBatch(selectedRows)

        // navigate(`/finance/purchases/subbies/batch/view/${batchKey}`)

   }

   const unassignRows = async () => {

    const updatedTasks = [...tasks];

    for await (let row of selectedRows) {

        await saveTask({tk_us_id: "NULL"}, row.tk_id)

        // need to update the tk_us_id and set the tasks again

        const taskIndex = tasks.findIndex(task => task.tk_id === row.tk_id);

        if (taskIndex !== -1) {
            // Copy the tasks array
            

            // Update the tk_us_id of the specific task
            updatedTasks[taskIndex] = { ...updatedTasks[taskIndex], tk_us_id: null, us_first_name: null, us_last_name: null };

            // Update the state
            
        }


    }

    setTasks(updatedTasks);

    setSelectedRows([])
    // let batchKey = await createSubbieBatch(selectedRows)

    // navigate(`/finance/purchases/subbies/batch/view/${batchKey}`)

}

   let modalcols = [
        {
            title: 'Name',
            render: item => `${item.us_first_name} ${item.us_last_name}`,
            key: 'firstname',
        },
    ]

    const onModalRowClick =  (record) => {
        return {
            onClick:  (event) => {

                assignRows(record.us_id, record.us_first_name, record.us_last_name)
            },
        };
    };

    const onRowClick = (record) => {
        return {
            onClick: event => {

                navigate('/tasks/view/' + record.tk_id)
            },
        };
    };

    return (
        tasks &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Tasks</h1>
                </div>
                <div>
                    <Select value={productFilter} onChange={(v) => setProductFilter(v) } style={{width: 250, marginRight: 10}}>
                        <Select.Option value="all">All products</Select.Option>
                        { products.map((p, i) => <Select.Option key={i} value={p.pr_id}>{p.pr_name}</Select.Option>)}
                    </Select>
                <DatePicker.RangePicker
                    onChange={changeDateRange} 
                    format={'DD/MM/YYYY'}
                    defaultValue={[dayjs(dates.from), dayjs(dates.to)]}
                    
                    ranges={
                        {
                            'Last month' : [
                                dayjs(dayjs().startOf('month').add(-1, 'month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), 
                                dayjs(dayjs().endOf('month').add(-1, 'month').endOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                            ],
                            'This month' : [
                                dayjs(dayjs().startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), 
                                dayjs(dayjs().endOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                            ],
                            'Next month' : [
                                dayjs(dayjs().add('1', 'month').startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), 
                                dayjs(dayjs().add('1', 'month').endOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                            ],
                            'Today +2 months' : [
                                dayjs(dayjs().startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), 
                                dayjs(dayjs().add('2', 'months').endOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                            ],
                        }
                    }
                /><br/>
                    { selectedRows.length > 0 && activeTab === "1" &&
                    <Button onClick={() => setShowModal(true)} type="primary" style={{marginTop: 20, float: 'right'}}>Assign selected</Button>}
                    { selectedRows.length > 0 && activeTab === "2" &&
                    <Popconfirm
                        title={"Are you sure"}
                        onConfirm={() => unassignRows() }
                    >
                    <Button type="primary" style={{marginTop: 20, float: 'right'}}>Unassign selected</Button>
                    </Popconfirm> }
                </div>
            </div>
            <Tabs activeKey={activeTab} onChange={(v) => setActiveTab(v)}>
                { assigned.toLowerCase() !== "mine" &&
                <Tabs.TabPane key="1" tab="Unassigned">
                    <Table 
                        dataSource={tasks.filter((t) => !t.tk_us_id).filter((t) => productFilter !== "all" ? t.tk_pr_id === productFilter : t)} 
                        rowKey="tk_id" 
                        columns={cols} 
                        pagination={false}
                        onRow={onRowClick}
                        rowSelection={ assigned.toLowerCase() === "mine" ? null : {
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRows(selectedRows)
                            //   console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                            }}
                        }
                    />
                </Tabs.TabPane> }
                <Tabs.TabPane key="2" tab="Outstanding">
                    <Table 
                        dataSource={tasks.filter((t) => t.tk_us_id && !t.tk_completed).filter((t) => productFilter !== "all" ? t.tk_pr_id === productFilter : t)} 
                        rowKey="tk_id" 
                        columns={cols} 
                        pagination={false}
                        onRow={onRowClick}
                        rowSelection={ assigned.toLowerCase() === "mine" ? null : {
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRows(selectedRows)
                            //   console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                            }}
                        }
                    />
                </Tabs.TabPane>
                <Tabs.TabPane key="3" tab="Complete">
                    <Table 
                        dataSource={tasks.filter((t) => t.tk_completed).filter((t) => productFilter !== "all" ? t.tk_pr_id === productFilter : t)} 
                        rowKey="tk_id" 
                        columns={
                            [...cols, {
                                title: "Completed",
                                key: "completed", 
                                render: item => dayjs(item.tk_completed).format('DD/MM/YYYY')
                            }]
                        } 
                        pagination={false}
                        onRow={onRowClick}
                    />
                </Tabs.TabPane>
            </Tabs>

            <Modal
                open={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                title="Add product"
                width={800}
                footer={false}
            >
                <>

                <Input value={userSearch} onChange={(e) => setUserSearch(e.target.value)} style={{marginBottom: 10}}/>

                <Table 
                    dataSource={users.filter((u) => (`${u.us_first_name} ${u.us_last_name}`).toLowerCase().includes(userSearch.toLowerCase()))} 
                    rowKey="us_id" 
                    columns={modalcols} 
                    pagination={false}
                    onRow={onModalRowClick}
                />
            </>
            </Modal>
        </div>
    )
}

export default AdminTasksList;