import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Loader from "../../System/Components/Loader"
import { Button, Card, Checkbox, Col, Form, Input, Row, notification, Select, Alert } from "antd"
import { useTasks } from "../../System/Global/DataHooks/useTasks"
import dayjs from 'dayjs'
import { useUsers } from "../../System/Global/DataHooks/useUsers"
import { FiArrowLeft } from "react-icons/fi";
import { v1 } from "uuid";
import axios from "axios"
import { useGlobal } from "../../System/Global/GlobalProvider"
import { get } from "../../System/Api/API"

const endpoint = "https://chat.kodie.co.uk/v1";
const scraperEndpoint = "https://chat.kodie.co.uk/v1/scraper";

const ViewTask = () => {

    const { UserData, campaign } = useGlobal();
    const { fetchTask, saveTask } = useTasks();
    const { tkId } = useParams();
    const [ loaded, setLoaded ] = useState(false)
    const [ task, setTask ] = useState();
    const [ form ] = Form.useForm(); 
    const [ isCompleted, setIsCompleted ] = useState(false)
    const { fetchAdminUsers } = useUsers()
    const navigate = useNavigate()
    const [ loading, setLoading ] = useState(false)
    const [ lh, setLh ] = useState()

    const [ users, setUsers ] = useState([])

    useEffect( () => {

        if (tkId) {

            getTask()
            getUsers()

        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tkId])

    const getUsers = async () => {

        setUsers(await fetchAdminUsers() )
    }

    const getTask = async () => {

        let _task = await fetchTask(tkId);
        setIsCompleted(_task.tk_completed)
        setTask(_task)
        console.log(_task)
        form.setFieldsValue({tk_notes: _task.tk_notes, tk_completed: dayjs(_task.tk_completed).format('YYYY-MM-DD')})

        // get the lighthouse report
        if (_task.pr_name.toLowerCase().includes("optimisation")) {

            const _lh = await get('lighthouse', {lh_cm_id: _task.cm_id});
            console.log('_lh', _lh)

            if (_lh.length > 0) {
                setLh(_lh[0])
            }

        }


        setLoaded(true)
    }

    const updateTask = async (values) => {

        if (isCompleted) {
            await saveTask( values, tkId);
        } else {
            await saveTask({...values, tk_completed: "NULL"}, tkId)
        }

        notification.success({
            message: "Success",
            description: "Task details have been saved."
        })

    }

    const setCompleted = async () => {

        let d = dayjs().format('YYYY-MM-DD');

        if (isCompleted) {
            form.setFieldsValue({tk_completed: null})
            d = "NULL"
        }

        setTask({...task, tk_completed: d});
        form.setFieldsValue({tk_completed: d})

        setIsCompleted(!isCompleted)

    }

    const handleSendMessage = async () => {
        setLoading(true);

        let userMessage = task.pr_prompt + " keywords: " + task.cm_keywords;
        
        let baseMessage = {
            role: "system",
            content: `You are a highly experienced British English content writer with over 50 years of expertise in crafting engaging, entertaining, newsworthy, and educational content. 
            Your writing adheres strictly to British English spelling and grammar conventions. 
            Your primary focus is to captivate the reader’s attention by delivering content that is thoughtful, insightful, and compelling. 
            You are not a salesperson, so your content should not be overly promotional. 
            Instead, aim to inform, educate, or entertain the audience in a professional and eloquent manner. 
            Your tone should be appropriate for the context, whether that’s formal or conversational, but always refined and engaging. 
            Avoid pushy or overtly persuasive language, and focus on delivering value through your words.
            Your replies should only contain and be formatted in HTML with H1, H2, H3 and <p> tags.`
        };

        console.log('userMessage', userMessage)

        form.resetFields();

        let postObject = {
            messages: [baseMessage, { role: 'user', content: userMessage }],
            model: "gpt-4o-mini"
        };

        const newId = v1();
        postObject.chat_id = newId;
        await sendMessage(postObject);
    };

    const sendMessage = async (postObject) => {
        

        console.log('postObject', postObject)

        let response = await axios.post(endpoint, postObject);

        console.log(response)

        const message = response.data.messages[response.data.messages.length - 1];

        console.log(message)

        // setMessages(response.data.messages);

        // const data = {
        //     chat_messages: response.data.messages,
        //     chat_us_id: UserData.us_id,
        //     chat_tokens: +response.data.total_tokens,
        // };

        // data.chat_name = values.message.substring(0, 30);
        // data.chat_id = postObject.chat_id;
        // await api.post({ table: "chat", data: response.data.messages[response.data.message.length -1].content });

        await saveTask({tk_result: message.content}, tkId)

        setTask({...task, tk_result: message.content})
        
        setLoading(false);


    };

    const handleRewrite = async () => {
        
        setLoading(true);

        let pagepostObject = {
            webpage: task.cm_target_pages[0].url,
            model: "gpt-4o-mini",
            chat_id: v1(),
        };

        console.log('pagepostObject', pagepostObject)

        let pageresponse = await axios.post(scraperEndpoint, pagepostObject);

        const oldContent = pageresponse.data.pageContent;
        console.log('oldContent', oldContent)

        
        let userMessage = "Rewrite the content of this page for me to help increase search ranking result. Old Content: " + oldContent;
            
        let baseMessage = {
            role: "system",
            content: `You are a highly experienced British English SEO content writer with over 50 years of expertise. 
            Your writing adheres strictly to British English spelling and grammar conventions. 
            Your primary focus is to captivate the reader’s attention by delivering content that is thoughtful, insightful, and compelling. 
            Your tone should be appropriate for the context, whether that’s formal or conversational, but always refined and engaging. 
            Avoid pushy or overtly persuasive language, and focus on delivering value through your words.
            Your replies should only contain and be formatted in HTML with H1, H2, H3 and <p> tags.`
        };

        console.log('userMessage', userMessage)

        let postObject = {
            messages: [baseMessage, { role: 'user', content: userMessage }],
            model: "gpt-4o-mini"
        };

        const newId = v1();
        postObject.chat_id = newId;
        await sendMessage(postObject);

        setLoading(false);

    };


    function extractWarningsAndErrors(lighthouseData) {
        
      
        // Access the first entry in lighthouseData
        lighthouseData = lighthouseData[0];
      
        const warningsAndErrors = [];
      
        // Loop through each audit
        for (const auditKey in lighthouseData.audits) {
            
            const audit = lighthouseData.audits[auditKey];
            const auditWarnings = audit.warnings || [];
      
            // Regular expression to find "Learn more" text and URL
            const learnMoreRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
            let learnMoreMatch;
            const learnMoreLinks = [];
      
            // Find all matches
            while ((learnMoreMatch = learnMoreRegex.exec(unescape(audit.description))) !== null) {
                learnMoreLinks.push({
                text: learnMoreMatch[1],
                url: learnMoreMatch[2]
                });
            }
      
            audit.description = unescape(audit.description)
            audit.description = audit.description.substring(0, audit.description.indexOf('['));
            
      
            // Collect audits with errors (score === 0 or actionable failure)
            if (audit.score === 0 || audit.details?.items?.some(item => item.failureType === 'Actionable')) {
                warningsAndErrors.push({
                id: audit.id,
                title: decodeURIComponent(audit.title),
                description: decodeURIComponent(audit.description),
                errors: audit.details?.items?.filter(item => item.failureType === 'Actionable') || [],
                learnMoreLinks: learnMoreLinks
                });
            }
        
            // Collect audits with warnings and examples from audit details
            if (auditWarnings.length > 0) {
                warningsAndErrors.push({
                id: audit.id,
                title: decodeURIComponent(audit.title),
                description: decodeURIComponent(audit.description),
                warnings: auditWarnings,
                learnMoreLinks: learnMoreLinks
                });
            }
        }
      
        return warningsAndErrors;
    }

    return (
        !loaded ? <Loader/> :
        task && users.length > 0 &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}><FiArrowLeft style={{cursor: 'pointer'}} onClick={() => navigate(-1) }/> Task Details</h1>
                </div>
            </div>
            
            <Form onFinish={updateTask} form={form} layout="vertical" initialValues={task}>
            <Row gutter={16}>
                    
                <Col span={12}>
                    
                        <Card style={{marginBottom: 30}}>
                            <Form.Item label="Campaign">
                                <Input value={task.cm_name} />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col span={14}>
                                    <Form.Item label="Product">
                                        <Input value={task.pr_name} />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Due date">
                                        <Input value={dayjs(task.tk_due).format('DD/MM/YYYY')} />
                                    </Form.Item>
                                </Col>

                                
                            </Row>

                            <Form.Item label="Target">
                                <Input value={task.pr_level === "Site" ? "Site" : task.tk_target_name} />
                            </Form.Item>
                            { task.pr_level === "Page" &&
                            <Form.Item label="Url">
                                <Input value={task.tk_target_url}/>
                            </Form.Item> }

                            { task.tk_us_id  &&
                            <Form.Item label="Assigned" name="tk_us_id">
                                <Select >
                                    { users.map((u) => <Select.Option value={u.us_id}>{`${u.us_first_name} ${u.us_last_name}`}</Select.Option>)}
                                </Select>
                                
                            </Form.Item> }


                        </Card>

                        <Card style={{marginBottom: 30}}>
                            
                            <Form.Item name="tk_notes" label="Notes">
                                <Input.TextArea rows={7}/>
                            </Form.Item>
                            
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item label="Completed">
                                        <Checkbox onChange={(e) => { setCompleted(e.target.checked)  } } checked={isCompleted}/>
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    { isCompleted &&
                                    <Form.Item name="tk_completed" label="Date Completed">
                                        <Input type="date"/>
                                    </Form.Item>}
                                </Col>
                                
                            </Row>
                            
                            <Form.Item>
                                <div style={{textAlign: 'right'}}> 
                                    <Button htmlType="submit" type="primary">Save</Button>
                                </div>
                            </Form.Item>
                        </Card>
                    
                </Col>

                <Col span={12}>

                {task.pr_name.toLowerCase().includes("optimisation") ?

                    lh ?
                    <Card style={{marginBottom: 30}}>
                        <h2>Optimisation Tasks</h2>

                        { extractWarningsAndErrors(lh.lh_data).map((audit) => {
                            return (
                                <div style={{ padding: '0 15px', marginBottom: 20 }} key={audit.id}>
                                    <h3>{audit.title}</h3>
                                    <p>{audit.description}</p>

                                    {/* Render "Learn more" links if they exist */}
                                    {audit.learnMoreLinks && audit.learnMoreLinks.map((link, index) => (
                                        <p key={index}>
                                            <a href={link.url} target="_blank" rel="noopener noreferrer">
                                                {link.text}
                                            </a>
                                        </p>
                                    ))}

                                    {/* Render errors, if any */}
                                    {audit.errors && audit.errors.map((error, index) => (
                                        <Card style={{ marginBottom: 20 }} key={index}>
                                            <h4>{error.url}</h4>
                                            <p>{error.failureReason}</p>
                                        </Card>
                                    ))}
                                </div>
                            )
                        })}
                    </Card>
                    :
                    <Card style={{marginBottom: 30}}>
                        <Alert message="No Lighthouse data found" type="warning" />
                    </Card>
                : null
                }
                {task.pr_name === "Blog Content" &&
                <Card style={{marginBottom: 30}}>
                    
                <Button onClick={handleSendMessage}>{task.tk_result ? "Regenerate" : "Generate"} content</Button>

                {loading ? <Loader/> :

                    task.tk_result && 
                    <div
                    style={{
                        padding: '30px',
                        borderRadius: '10px',
                        backgroundColor: '#fff'
                    }}
                    dangerouslySetInnerHTML={{ __html: (task.tk_result).replace('```html', '').replace('```', '') }}
                ></div>
                    
                    }

                </Card> }

                {task.pr_name.toLowerCase().includes("tpo") &&

                <Card style={{marginBottom: 30}}>
                    
                <Button onClick={handleRewrite}>{task.tk_result ? "Regenerate" : "Generate"} content</Button>

                {loading ? <Loader/> :

                    task.tk_result && 
                    <div
                    style={{
                        padding: '30px',
                        borderRadius: '10px',
                        backgroundColor: '#fff'
                    }}
                    dangerouslySetInnerHTML={{ __html: (task.tk_result).replace('```html', '').replace('```', '') }}
                ></div>
                    
                    }

                </Card> }

                    
                </Col>

            </Row>

            <Row gutter={16}>

                <Col span={24}>

                </Col>

            </Row>
            </Form>
            
            
        </div>
    )
}

export default ViewTask