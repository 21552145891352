export function getMasonicProvinces() {
     return {
         1: "Bedfordshire",
         2: "Berkshire",
         3: "The Province of Bristol",
         4: "Buckinghamshire",
         5: "Cambridgeshire",
         6: "Cheshire",
         7: "Cornwall",
         8: "Cumberland and Westmorland",
         9: "Derbyshire",
         10: "Devonshire",
         11: "Dorset",
         12: "Durham",
         13: "Essex",
         14: "Gloucestershire",
         15: "Guernsey and Alderney",
         16: "Hampshire and Isle of Wight",
         17: "Herefordshire",
         18: "Hertfordshire",
         19: "Jersey",
         20: "Kent",
         21: "Lancashire (East)",
         22: "Lancashire (West)",
         23: "Leicestershire and Rutland",
         24: "Lincolnshire",
         25: "London",
         26: "Middlesex",
         27: "Monmouthshire",
         28: "Norfolk",
         29: "Northamptonshire and Huntingdonshire",
         30: "Northumberland",
         31: "Nottinghamshire",
         32: "Oxfordshire",
         33: "Shropshire",
         34: "Somerset",
         35: "Staffordshire",
         36: "Suffolk",
         37: "Surrey",
         38: "Sussex",
         39: "Warwickshire",
         40: "Wiltshire",
         41: "Worcestershire",
         42: "Yorkshire (West Riding)",
         43: "Yorkshire, North and East Ridings",
         44: "South Wales (Eastern Division)",
         45: "South Wales (Western Division)",
         46: "West Wales",
         47: "Isle of Man",
         48: "Channel Islands",
         49: "Yorkshire, North and East Ridings"
     };
 }
 