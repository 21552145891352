import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useUsers } from "../../System/Global/DataHooks/useUsers"
import Loader from "../../System/Components/Loader"
import { Alert, Button, Card, Col, Form, Input, Row, notification, Popconfirm } from "antd"

const ViewUser = () => {

    const [ user, setUser ] = useState()
    const { fetchUser, saveUser, savePassword, removeUser } = useUsers();
    const { usId } = useParams();
    const [ loaded, setLoaded ] = useState(false)
    const [ form ] = Form.useForm(); 
    const [ form2 ] = Form.useForm(); 
    const navigate = useNavigate();
    const [ pwd, setPwd ] = useState("")

    useEffect( () => {

        if (usId) {

            getUser()

        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usId])

    const getUser = async () => {

        let _user = await fetchUser(usId);
        setUser(_user)
        form.setFieldsValue(_user)
        setLoaded(true)
    }

    const updateUser = async (values) => {

        console.log(values)

        let us_id = await saveUser(values, usId);

        if (us_id) {
            notification.success({
                message: "Success",
                description: "User details have been saved."
            })
        }

    }

    const updatePassword = async (values) => {

        let pwd = await savePassword(values.password, usId)

        notification.success({
            message: "Success",
            description: "User password have been updated."
        })

        setUser({...user, us_password_hash: pwd})

    }

    // Custom validator for the confirm password
    const validateConfirmPassword = (_, value) => {
        if (!value || pwd === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('The two passwords that you entered do not match!'));
    };

    // Custom validator for the password policy
    const passwordPolicy = (_, value) => {
        const regex = /^(?=.*[!@#$%^&*]).{8,}$/; // This regex checks for at least one special character and at least 8 characters in total
        if (!value || regex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Password must be at least 8 characters long and include a special character.'));
    };

    const deleteUser = async () => {


        await removeUser(usId)

        navigate(-1)

    }

    return (
        user &&
        !loaded ? <Loader/> :
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    { user ?
                    <h1 style={{marginTop: 5}}>{user.us_first_name} {user.us_last_name}</h1>
                    : <h1 style={{marginTop: 5}}>Add new user</h1> }
                </div>
            </div>

            
            <Row gutter={16}>
                    
                <Col span={12}>
                    <Form onFinish={updateUser} form={form} layout="vertical" initialValues={user}>
                        <Card style={{marginBottom: 30}}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="us_first_name" label="First name" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a first name!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="us_last_name" label="Last name" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a last name!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item name="us_title" label="Job title" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a job title!',
                                }]}>
                                <Input />
                            </Form.Item>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="us_email" label="Email address" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter an email address!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="us_phone" label="Phone" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a phone number!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name="us_role" label="Role" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a role for the user!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="us_status" label="Status" rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a status for the user!',
                                        }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                            
                                <div style={{textAlign: 'right'}}> 
                                    <Button style={{marginRight: 5}} onClick={() => navigate('/users/list/admin')}>Cancel</Button>
                                    <Button htmlType="submit" type="primary">Save</Button>
                                </div>
                                <div style={{textAlign: 'left'}}> 
                                    <Popconfirm onConfirm={deleteUser} title="Are you sure?" >
                                        <Link>Delete user</Link>
                                    </Popconfirm>
                                </div>
                            </Form.Item>
                        </Card>
                    </Form>
                </Col>

                { user &&
                <Col span={12}>
                    <Form onFinish={updatePassword} form={form2} layout="vertical" initialValues={user ? user : {}}>
                        <Card style={{marginBottom: 30}}>
                            { !user.us_password_hash && <Alert style={{marginBottom: 30}} type="error" description="This user does not have a password set"/> }
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    { required: true, message: 'Please input your password!' },
                                    { validator: passwordPolicy }
                                ]}
                            >
                                
                                <Input.Password value={pwd} onChange={(e) => setPwd(e.target.value) }  />
                            </Form.Item>

                            <Form.Item
                                label="Confirm Password"
                                name="confirm_password"
                                dependencies={['password']} // This makes the confirm password field dependent on the password field for validation
                                hasFeedback
                                rules={[
                                    { required: true, message: 'Please confirm your password!' },
                                    { validator: validateConfirmPassword }
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item>
                                <div style={{textAlign: 'right'}}> 
                                    <Button htmlType="submit" type="primary">
                                        { !user.us_password_hash ? "Set password" : "Update password" }
                                    </Button>
                                </div>
                            </Form.Item>
                        </Card>
                    </Form>
                </Col>  }
            </Row>
            
            
        </div>
    )
}

export default ViewUser