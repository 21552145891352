import { Route, Routes } from 'react-router-dom';
import { AdminDashboard } from '../../Components/Dashboard/AdminDashboard';
import { FiHome } from 'react-icons/fi';

export const DashboardMenu = () => ( {
     key: "1",
     link: "/admindashboard",
     label: 'Dashboard',
     icon: <FiHome/>,
     
})

export const dashboardRoutes = () => (
     <Routes> 
          <Route path="admindashboard" element={<AdminDashboard />} />
     </Routes>
)