import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table } from 'antd';
import { useUsers } from '../../System/Global/DataHooks/useUsers';

export const UsersList = () => {

    const { fetchUsers, fetchAdminUsers } = useUsers();
    const [ users, setUsers ] = useState([])
    const navigate = useNavigate();
    const { status } = useParams();
    
    useEffect( () => {

        ( async () => {

            if (status.toLowerCase() === "admin") {

                let _users = [];
                _users = await fetchAdminUsers()
                console.log(_users)
                setUsers(_users)

            } else {

                let _users = [];
                _users = await fetchUsers()
                setUsers(_users)
            }
            
                
            
        })()

        return () => {
            setUsers([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    let cols = [
        {
            title: 'Name',
            render: item => `${item.us_first_name} ${item.us_last_name}`,
            key: 'us_name',
        },
        {
            title: 'Title',
            render: item => `${item.us_title || ""}`,
            key: 'us_title',
        },
        {
            title: 'Email',
            render: item => `${item.us_email}`,
            key: 'us_email',
        },
        {
            title: 'Status',
            render: item => `${item.us_status}`,
            key: 'us_status',
        },
        
    ]


    const onRowClick = (record) => {
        return {
            onClick: event => {

                navigate('/users/view/' + record.us_id)
                
            },
        };
    };

    
   
    return (
        users &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1>{status[0].toUpperCase() + status.slice(1)} Users</h1>
                </div>
            </div>
            <Table 
                dataSource={users} 
                rowKey="us_id" 
                columns={cols} 
                pagination={false}
                onRow={onRowClick}
            />
        </div>
    )
}

export default UsersList;