import { Card, Col, Row, Table, Tabs, Tag } from "antd"
import { useGlobal } from "../../System/Global/GlobalProvider"
import { useLighthouse } from "../../System/Global/DataHooks/useLighthouse";
import { useEffect, useState } from "react";
import dayjs from 'dayjs'
import Loader from "../../System/Components/Loader";
import { Link, useParams } from "react-router-dom";
import { lighthouseCategories } from "./lighthouseCategories";
import { Chart }                        from 'react-google-charts';

export const LighthouseView = () => {

    const { campaign } = useGlobal();
    const { fetchLighthouse, fetchPageLighthouseLastSix } = useLighthouse();
    const { lhid } = useParams();
    const [ report, setReport ] = useState({})
    const [ activeKey, setActiveKey ] = useState("1")
    const [ graphData, setGraphData ] = useState([])

    useEffect( () => {

        ( async () => {

            if (campaign && lhid) {

                let _report = await fetchLighthouse(lhid)
                setReport(_report)

                let _graphData = [['Date', 'SEO', 'Performance', 'Best practice', 'Accessibility']]

                const lastSix = await fetchPageLighthouseLastSix(_report.lh_target_url)

                for await (let l of lastSix) {

                    let gd = [
                        dayjs(l.lh_created).format('DD/MM/YYYY'),
                        l.lh_s,
                        l.lh_p,
                        l.lh_b,
                        l.lh_a,
                    ] 

                    _graphData.push(gd);
                }

                setGraphData(_graphData);
                
            }

        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign])

    
    return (
        report && graphData && report?.lh_data ?
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Lighthouse Report - {report.lh_target_name}</h1>
                    <p>Created: {dayjs(report.lh_created).format('DD/MM/YYYY')}</p>
                </div>
            </div>

            <div>
                <Card style={{marginBottom: 30}}>
            <Chart
                    // ref={chartRef}
                    chartType="LineChart"
                    data={graphData}
                    width="100%"
                    height="400px"
                    options={{
                        hAxis: {
                            // showTextEvery: labelCount,
                            title: '',
                            textStyle: {color: '#333', fontSize: '14'}
                        },
                        vAxis: {
                            minValue: 0,
                            format: 0,
                            viewWindow: {min: 0},
                            gridlines: { color: 'transparent'},
                            textStyle: {color: '#333', fontSize: '14'}
                        },
                        title: '',
                        colors: ['#51449B','#5E81DD','#239454', '#F58C6D', '#e75172'],
                        areaOpacity: 0,
                        pointSize: 10,
                        lineWidth: 6,
                        theme: 'material',
                        curveType: 'function',
                        legend: {position: 'none'},
                        width: '100%',
                        chartArea: {'width': '95%', 'height': '70%'},
                        backgroundColor: '#FDFDFD',
                    }}
                    legendToggle
                />
                </Card>
            </div>

            <div>
                    <LighthouseDashboard activeKey={activeKey} setActiveKey={setActiveKey} report={report}/>
                </div>

                <div>

                    <Tabs activeKey={activeKey}>
                        <Tabs.TabPane key="1">
                            <ReportSection data={report.lh_data[0]} category="seo"/>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2">
                            <ReportSection data={report.lh_data[0]} category="performance"/>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="3">
                            <ReportSection data={report.lh_data[0]} category="best-practices"/>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="4">
                            <ReportSection data={report.lh_data[0]} category="accessibility"/>
                        </Tabs.TabPane>
                    </Tabs>

                    

                </div>
            
        </div>
        : <Loader/>
    )

}

export default LighthouseView;

const ReportSection = ({data, category}) => {

    const cat = lighthouseCategories[category];

    const [ warnings, setWarnings ] = useState([])
    const [ info, setInfo ] = useState([])

    useEffect( () => {

        ( async () => {

            let _warnings = [];
            let _info = [];

            for await (let ref of cat.auditRefs) {

                const audit = data.audits[ref.id];

                if (audit) {

                    let dataItem = {
                        index:          ref.id,
                        score:          +audit.score,
                        title:          audit.title,
                        displayValue:   audit.displayValue,
                        details:        generateLink(audit.description)
                    }
    
                    if (+audit.score < 0.5) {
                        _warnings.push(dataItem)
                    } else if (+audit.score < 0.8) {
                        _info.push(dataItem)
                    } 
                }

                


            }

            setWarnings(_warnings);
            setInfo(_info);

        })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const generateLink = (d) => {

        let fsb = d.indexOf('[Learn ')
        let newDetails;
        
        if (fsb > -1) {

            let fb = d.indexOf('(', fsb)
            let lb = d.indexOf(')', fsb)
            let link = d.substring(fb + 1, lb)

            newDetails = <div>{d.substring(0, fsb)} <Link to="#" onClick={() => window.open(link)}>Learn more</Link></div>
            
        } else {
            newDetails = d;
        }

        return newDetails;
        

    }

    let cols = [
        {
            title: 'Item',
            render: item => <div>
                <div style={{}}>
                    <h4 style={{margin: 0, marginBottom: 10}}>
                        {item.title} 
                        { item.displayValue ? <span> - <Tag>{item.displayValue}</Tag></span> : null } 
                        { +item.score ? 
                            <Tag color={+item.score > 0.8 ? "green" : +item.score > 0.5 ? "orange" : "red"}>
                                {(+item.score * 100).toFixed(0)}%
                            </Tag> 
                        : null
                        }
                    </h4>
                    {item.details}
                </div>
            </div>,
            key: 'item',
        },
        
    ]

    return (
        <div>
            <div>
                <h2>Warnings</h2>
                <Table dataSource={warnings} pagination={false} columns={cols} rowKey={"index"}/>
                
                { info.length > 0 &&
                <>
                <h2>Info</h2>
                <Table dataSource={info} pagination={false} columns={cols} rowKey={"index"}/> </> }

            </div>
        </div>
    )
}


const LighthouseDashboard = ({report, setActiveKey, activeKey}) => {

    const DbCard = ({title, score, onClick, active, headcolor}) => {

        return (
            <Col span={6}>
                <Card className="dbCard" headStyle={{color: "white", backgroundColor: active ? `${headcolor}` : `lighten(${headcolor}, 4)`}} title={title} onClick={onClick} hoverable >
                    <div className="dbStat">
                        {!isNaN(score) ? score : 0}
                    </div>
                    <div className="dbDesc">
                        {/* {description} */}
                    </div>
                </Card>
            </Col>
        )
    }

    return (
        <div>
            <Row gutter={16}>
                <DbCard active={activeKey === "1"} headcolor="#FFD100" onClick={() => setActiveKey("1") } title="SEO" score={report.lh_s} description={"SEO"}/>
                <DbCard active={activeKey === "2"} headcolor="#FFD100" onClick={() => setActiveKey("2") } title="Performance" score={report.lh_p} description={"SEO"}/>
                <DbCard active={activeKey === "3"} headcolor="#FFD100" onClick={() => setActiveKey("3") } title="Best Practice" score={report.lh_b} description={"SEO"}/>
                <DbCard active={activeKey === "4"} headcolor="#FFD100" onClick={() => setActiveKey("4") } title="Accessibility" score={report.lh_a} description={"SEO"}/>
            </Row>
        </div>
    )

}