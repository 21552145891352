import { raw, remove, save } from '../../Api/API';  // Adjust the path accordingly
import { escapeNested, parseStringifiedArrays, stringifyArraysInObject, unescapeNested } from '../unescapeArray';
import bcrypt from 'bcryptjs';

const useUsers = () => {


    const saveUser = async (newItems, us_id) => {

        newItems = await escapeNested(newItems);
        newItems = await stringifyArraysInObject(newItems);

        if (us_id) {
            await save('users', newItems, {us_id})
        } else {

            us_id = save('users', newItems)
        }
        return us_id;

    };

    const getUser = async (id) => {

        const user = await raw(`
            SELECT * FROM users 
            WHERE
                us_id = "${id}"
        `)
        
        return await unescapeNested(user[0]);
    }

    const fetchUser = async (id) => {

        const user = await raw(`
            SELECT * FROM users 
            WHERE
                us_id = "${id}"
        `)
        
        return await unescapeNested(user[0]);
    }

    const fetchAdminUsers = async () => {

        let results = await raw(`
            SELECT * FROM users 
            WHERE
                us_role = "Admin"
        `)
        
        results = await parseStringifiedArrays(results);
        results = await unescapeNested(results)

        return results;

    }

    const fetchUsers = async () => {

        let results = await raw(`
            SELECT * FROM users 
            WHERE
                us_role = "User"
        `)
        
        results = await parseStringifiedArrays(results);
        results = await unescapeNested(results)

        return results;

    }

    const savePassword = async (password, us_id) => {

        const salt = bcrypt.genSaltSync(10);
        const hashedPassword = bcrypt.hashSync(password, salt);

        if (us_id) {
            await save('users', {us_password_hash: hashedPassword}, {us_id})
        }

        return hashedPassword;
        
    }

    const removeUser = async (us_id) => {

        if (us_id) {

            await remove('users', {us_id: us_id})
        }


    }

    return { getUser, fetchUser, fetchAdminUsers, fetchUsers, saveUser, savePassword, removeUser };
};

export { useUsers };
