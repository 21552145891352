import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Input, Row, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useCampaigns } from '../../System/Global/DataHooks/useCampaigns';
import { useGlobal } from '../../System/Global/GlobalProvider';
import SiteConfig from '../../System/SiteConfig';
import { get, remove, save } from '../../System/Api/API';


export const Changelog = () => {

    const { setCampaign, UserData } = useGlobal();
    const { fetchCampaigns } = useCampaigns();
    const [ campaigns, setCampaigns ] = useState([])
    const navigate = useNavigate();
    const [ data, setData ] = useState([])
    
    useEffect( () => {

        ( async () => {
                let _campaigns = [];
                _campaigns = await fetchCampaigns()
                setCampaigns(_campaigns)                

                let _data = await get('notify', null, {created: 'DESC'})
                setData(_data)

        })()

        return () => {
            setCampaigns([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    

    let cols = [
        {
            title: 'Created',
            key: 'date',
            render: item => dayjs(item.created).format('DD/MM/YYYY')
        },
        {
            title: 'Service Id',
            render: item => `${item.data.service_id}`,
            key: 'cm_service_id',
        },
        {
            title: 'Campaign',
            render: item => {

                console.log(item.data.service_id)
                console.log(campaigns)
                let c = campaigns.find((x) => +x.cm_service_id === +item.data.service_id)
                console.log('c', c)

                return c ? c.cm_name : "Unknown"
            },
            key: 'campaign',
        },
        {
            title: 'Status',
            render: item => `${item.data.record_type === "C" ? "Cancelled" : item.data.record_type === "U" ? "Updated" : item.data.record_type === "N" ? "New" : "Unknown"}`,
            key: 'status',
        },
        // {
        //     title: 'data',
        //     render: item => `${item.data}`,
        //     key: 'date',
        // },
    ]


    const onRowClick = (record) => {
        return {
            onClick: event => {

            },
        };
    };

    
   
    return (
        data && campaigns &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Change log</h1>
                </div>
            </div>
            <Table 
                dataSource={data} 
                rowKey="id" 
                columns={cols} 
                pagination={false}
                onRow={onRowClick}
            />
            
        </div>
    )
}

export default Changelog;