import React from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import './AntCss.css';
import MainLayout from './System/Layout/MainLayout';
import { GlobalProvider, useGlobal } from './System/Global/GlobalProvider';
import LoginPage from './Components/Authentication/Login';
// import SignupPage from './Components/Authentication/SignupPage';
import SearchResults from './System/Layout/SearchResults';
import { campaignRoutes } from './System/Layout/CampaignMenu';
import CampaignList from './Components/Campaigns/CampaignList';
import AddCampaign from './Components/Campaigns/AddCampaign';
import { productRoutes } from './System/Layout/ProductMenu';
import { packageRoutes } from './System/Layout/PackageMenu';
import { tasksRoutes } from './System/Layout/TasksMenu';
import { lighthouseRoutes } from './System/Layout/LighthouseMenu';
import { dashboardRoutes } from './System/Layout/DashboardMenu';
import { usersRoutes } from './System/Layout/UsersMenu';
import { filesRoutes } from './System/Layout/FilesMenu';
import TransferCampaigns from './Components/Campaigns/TransferCampaigns';
import BulkGenerateTasks from './Components/Campaigns/BulkGenerateTasks';
import { billingRoutes } from './System/Layout/BillingMenu';
import Changelog from './Components/Campaigns/Changelog';

function App() {
  return (
      <>
        <GlobalProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    {/* <Route path="/signup" element={<SignupPage />} /> */}
                    <Route path="/*" element={<MainLayoutWithRoutes />} />
                </Routes>
            </BrowserRouter>
        </GlobalProvider>
      </>
  );
}

function MainLayoutWithRoutes() {

	const { checkAuth, campaign } = useGlobal();
	const navigate = useNavigate();

	if (!checkAuth()) { navigate('/login') } 

	return (
		checkAuth() ?

			campaign ?
			<MainLayout>
				{ dashboardRoutes() }
				{campaignRoutes()}
				{productRoutes()}
				{packageRoutes()}
				{tasksRoutes()}
				{lighthouseRoutes()}
				{ usersRoutes() }
				{ filesRoutes() }
				{ billingRoutes() }
				<Routes>
				<Route path="campaigns/transfer" element={<TransferCampaigns />} />
				<Route path="changelog" element={<Changelog />} />
				<Route path="campaigns/generate" element={<BulkGenerateTasks />} />
					<Route path="search" element={<SearchResults />} />
				</Routes>
			</MainLayout>

			:

			<MainLayout>
				<Routes>
					<Route path="campaigns/add" element={<AddCampaign />} />
					<Route path="campaigns/transfer" element={<TransferCampaigns />} />
					<Route path="campaigns/generate" element={<BulkGenerateTasks />} />
					<Route path="*" element={<CampaignList />} />
				</Routes>
			</MainLayout>

		: 
			<Routes>
				<Route path="/*" element={<LoginPage />} />
			</Routes>
	);
}

export default App;

