import { Route, Routes } from 'react-router-dom';
import { AdminDashboard } from '../../Components/Dashboard/AdminDashboard';
import { FiFolder, FiHome } from 'react-icons/fi';
import { FileViewer } from '../../Components/SiteWide/FileViewer';

export const FilesMenu = () => ( {
     key: "4",
     link: "/files",
     label: 'Files',
     icon: <FiFolder/>,
     
})

export const filesRoutes = () => (
     <Routes> 
          <Route path="files" element={<FileViewer />} />
     </Routes>
)