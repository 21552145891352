import { useEffect, useState } from 'react';
import { Alert, Button, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useTasks } from '../../../System/Global/DataHooks/useTasks';
import { usePackages } from '../../../System/Global/DataHooks/usePackages';
import { useGlobal } from '../../../System/Global/GlobalProvider';
import { useProducts } from '../../../System/Global/DataHooks/useProducts';
import { useCampaigns } from '../../../System/Global/DataHooks/useCampaigns';

export const TasksList = () => {

    const { fetchTasks, saveTask } = useTasks();
    const { campaign, setCampaign } = useGlobal();
    const [ tasks, setTasks ] = useState()
    const { packages } = usePackages();
    const { products } = useProducts();
    const { saveCampaign } = useCampaigns();
    
    useEffect( () => {

        ( async () => {
            
                let _tasks = [];
                _tasks = await fetchTasks()
                
                setTasks(_tasks)
                
            
        })()

        return () => {
            setTasks([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let cols = [
        {
            title: 'Product Name',
            render: item => `${item.pr_name}`,
            key: 'pr_name',
        },
        {
            title: 'Target',
            render: item => item.pr_level === "Page" ? <div>{item.tk_target_name} ({item.tk_target_url})</div> : <div>Site</div>,
            key: 'pr_level',
        },
        {
            title: 'Month',
            key: 'month',
            render: item => item.tk_month
        },
        {
            title: 'Due',
            key: 'date',
            render: item => {

                return (
                    <div>{dayjs(item.tk_due).format('DD/MM/YYYY')}</div>
                )
            }
        },
        
    ]


    const onRowClick = (record) => {
        return {
            onClick: event => {

                // select campign
                // navigate('/dashboard')
            },
        };
    };

    const generateTasks = async () => {

        // get the campaign target pages

        const _targetPages = campaign.cm_target_pages;

        // get the package
        const _package = packages.find((p) => p.pk_id === campaign.cm_pk_id)

        const newTasks = [...tasks]

        for await (let [index, ps] of _package.pk_schedule.entries()) {

            for await (let psi of ps.items) {

                let product = products.find((p) => p.pr_id === psi.pr_id);

                
                let taskData = {
                    tk_cm_id:       campaign.cm_id,
                    tk_pr_id:       product.pr_id,
                    tk_due:         dayjs().add(index+1, 'months').format('YYYY-MM-DD'),
                    tk_month:       index+1,
                    tk_pk_id:       _package.pk_id,
                    tk_target_name:      product.pr_level === "Page" ? _targetPages[psi.page -1].name : "",
                    tk_target_url:      product.pr_level === "Page" ? _targetPages[psi.page -1].url : "",
                }

                await saveTask(taskData);

                
                

                taskData = {...taskData, ..._package, ...product}

                newTasks.push(taskData)

            }
            
        }

        await saveCampaign({cm_onboarded: '1'});
        setCampaign({...campaign, cm_onboarded: 1});

        setTasks(newTasks)

    }

    
   
    return (
        tasks &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Tasks</h1>
                </div>
            </div>
            { tasks.length > 0 ?
            <Tabs defaultActiveKey='1'>
                <Tabs.TabPane key="1" tab="Outstanding">
                    <Table 
                        dataSource={tasks.filter((t) => !t.tk_completed)} 
                        rowKey="tk_id" 
                        columns={cols} 
                        pagination={false}
                        onRow={onRowClick}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane key="2" tab="Complete">
                    <Table 
                        dataSource={tasks.filter((t) => t.tk_completed)} 
                        rowKey="tk_id" 
                        columns={cols} 
                        pagination={false}
                        onRow={onRowClick}
                    />
                </Tabs.TabPane>
            </Tabs>
            
            :
            <>
                { campaign.cm_onboarded === 1 ?
                <Button onClick={generateTasks} type="primary">Generate tasks</Button>  
                : <div><Alert type='warning' message="No target pages currently added to this campaign"/></div> }
            </>
            }
        </div>
    )
}

export default TasksList;