import { Button, Card, Col, Form, Input, Row, Table } from 'antd';
import { useCampaigns } from '../../../System/Global/DataHooks/useCampaigns';
import { useGlobal } from '../../../System/Global/GlobalProvider';
import { checkUrl } from '../../../System/Api/API';
import { usePackages } from '../../../System/Global/DataHooks/usePackages';
import { useEffect, useState } from 'react';

export const TargetPages = () => {

    const [ form ] = Form.useForm();
    const { saveCampaign } = useCampaigns();
    const { setCampaign, campaign } = useGlobal();
    const { packages} = usePackages();
    const [ pages, setPages ] = useState(0)
    
    useEffect( () => {

        if (packages?.length > 0 && campaign) {

            console.log(packages, campaign.cm_pk_id)

            let p = packages.find((p) => p.pk_id === campaign.cm_pk_id)
            setPages(p.pk_pages)
            
        }

    }, [packages, campaign])

    const addTargetPage = async (values) => {

        setCampaign(
            {
                ...campaign, 
                cm_target_pages: [...campaign.cm_target_pages, {...values, index: campaign.cm_target_pages.length+1}]
            }
        )
        await saveCampaign({cm_target_pages: [...campaign.cm_target_pages, {...values, index: campaign.cm_target_pages.length+1}]}, campaign.cm_id)
        form.setFieldsValue({name: "", url: ""})

    }
    const removeTargetPage = async (index) => {

        setCampaign(
            {
                ...campaign,
                cm_target_pages: campaign.cm_target_pages.filter((t) => t.index !== index)
            }
        )

        await saveCampaign({cm_target_pages: campaign.cm_target_pages.filter((t) => t.index !== index)}, campaign.cm_id)
        
    }

    let cols = [
        {
            title: "Name",
            render: item => `${item.name}`,
            key: 'name'
        },
        {
            title: 'URL',
            key: 'url',
            render: item => `${item.url}`,
        },
        {
            title: '',
            key: 'remove',
            render: item => {
                return (
                    campaign.cm_onboarded === 0 &&
                    <div style={{textAlign: 'right'}}>
                        <Button onClick={ () => removeTargetPage(item.index)}>X</Button>
                    </div>
                )
            }
        },
        
    ]

    const validateUrl = async (_, url) => {
        if (!url) {
            // If the URL is empty, you might want to allow it or show a different message
            return Promise.reject(new Error('URL is required'));
        }
    
        try {
            const responseStatus = await checkUrl('https://' + url);
            if (responseStatus === 200) {
                // If the URL is valid 
                return Promise.resolve();
            } else {
                // If the URL is not valid
                return Promise.reject(new Error('Please enter a valid URL'));
            }
        } catch (error) {
            // If there's an error (e.g., network error), you can handle it accordingly
            return Promise.reject(new Error('URL validation failed'));
        }
    };

    return (
        campaign &&
        <>
            <Form onFinish={addTargetPage} form={form} layout="vertical">
                <Row gutter={16}>
                    <Col span={24}>
                        <Card title={`Target pages - Max ${pages}`} style={{marginBottom: 30}}>
                            { pages > campaign.cm_target_pages?.length &&
                            <Row gutter={16}>
                                <Col span={10}>
                                <Form.Item name="name" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a page name!',
                                }]}>
                                    <Input placeholder='Target page name...'/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="url" rules={[
                                    {
                                        required: true,
                                        validator: validateUrl,
                                    }]}
                                    validateTrigger='onBlur'
                                    >
                                        
                                        <Input prefix="https://" placeholder="Website page url" />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button htmlType='submit' style={{height: 44}}>Add</Button>
                                </Col>
                            </Row> }
                            
                            <Table 
                                style={{marginTop: 30}}
                                dataSource={campaign.cm_target_pages} 
                                rowKey="index" 
                                columns={cols} 
                                pagination={false}
                                // onRow={onRowClick}
                            />
                            
                        </Card>
                        
                    </Col>
                </Row>
                
            </Form>
            
        
        
        </>
    )
}

export default TargetPages;