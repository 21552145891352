import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Modal, Row, Table } from 'antd';
import { usePackages } from '../../System/Global/DataHooks/usePackages';
import { useEffect, useState } from 'react';
import { useProducts } from '../../System/Global/DataHooks/useProducts';

export const AddPackage = () => {

    const navigate = useNavigate();
    const [ form ] = Form.useForm();
    const { products } = useProducts();
    const { savePackage, fetchPackage } = usePackages();
    const { pkid } = useParams();
    const [ _package, setPackage ] = useState()
    const [ currentIndex, setCurrentIndex ] = useState()
    const [ showModal, setShowModal ] = useState(false)
    const [ productSearch, setProductSearch ] = useState("")

    useEffect( () => {

        ( async () => {

            if (pkid) {

                let _p = await fetchPackage(pkid)
                setPackage(_p)
                form.setFieldsValue(_p)
            }

        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pkid])

    const savePackageDetails = async (values) => {

        let data = {
            ...values, 
            pk_schedule: _package ? [..._package.pk_schedule] : [{items: []}],
        }

        if (pkid) {

            await savePackage(data, pkid);
            navigate(`/packages/list`)

        } else {

            let id = await savePackage(data);
            navigate(`/packages/view/${id}`)
        }
        

        
        
    }

    const addMonth = () => {

        setPackage({..._package, pk_schedule: [..._package.pk_schedule, {items: []}]})

        savePackage({
            pk_schedule: [..._package.pk_schedule, 
                {
                    items: []
                }
            ]
        }, pkid)

    }

    const addProduct = async (monthIndex) => {

        setCurrentIndex(monthIndex);
        setShowModal(true)

    }

    let cols = [
        {
            title: 'Name',
            render: item => `${item.pr_name}`,
            key: 'pr_name',
        },
        {
            title: 'Category',
            render: item => `${item.pr_category}`,
            key: 'pr_category',
        },
        {
            title: 'Level',
            render: item => `${item.pr_level}`,
            key: 'level',
        },
        {
            title: 'Minutes',
            render: item => `${item.pr_minutes}`,
            key: 'pr_minutes',
        },
    ]

    const onRowClick =  (record) => {
        return {
            onClick:  (event) => {

                let _schedule = _package.pk_schedule;
                _schedule[currentIndex].items.push({pr_id: record.pr_id, page: record.pr_level === "Page" ? '1' : '0' })
                
                setPackage({
                    ..._package,
                    pk_schedule: _schedule
                })

                savePackage({pk_schedule: _schedule}, pkid)
            },
        };
    };

    const removeLine =  (x, i) => {

        let _schedule = [..._package.pk_schedule];
        _schedule[x].items.splice(i, 1);
        
        setPackage({
            ..._package,
            pk_schedule: _schedule
        })

        savePackage({pk_schedule: _schedule}, pkid)
    };

    const changeProductPage = (x, i, e) => {

        let value = parseInt(e.target.value, 10);
        if (value < 1) {
            value = 1;
        } else if (value > +_package.pk_pages) {
            value = +_package.pk_pages;
        }

        let _schedule = _package.pk_schedule;

        _schedule[x].items[i].page = `${value}`;
        setPackage({..._package, pk_schedule: _schedule})
        savePackage({pk_schedule: _schedule}, pkid)
    }

    const onProductPageChange = (x, i, e) => {

        let _schedule = _package.pk_schedule;
        _schedule[x].items[i].page = `${e.target.value}`;
        setPackage({..._package, pk_schedule: _schedule})

    }
    

    return (
        
        <>
        <h1>{_package ? "Edit Package" : "New Package"}</h1>
            <Form onFinish={savePackageDetails} form={form} layout="vertical" initialValues={{pk_pages: 1}}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Card style={{marginBottom: 30}}>
                            <Row gutter={16}>
                                <Col span={12}>
                                <Form.Item name="pk_name" label="Package name" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a package name!',
                                }]}>
                                    <Input />
                                </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name="pk_pages" label="Pages" rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a number of pages!',
                                    }]}>
                                        <Input type="number" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name="pk_price" label="Monthly Price" rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a price',
                                    }]}>
                                        <Input type="number" />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item style={{textAlign: 'right'}}>
                                        <Button style={{marginRight: 5}} onClick={() => navigate('/packages/list')} htmlType='button'>Cancel</Button>
                                        <Button type="primary" htmlType='submit'>Save</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            
                        </Card>
                    </Col>
                </Row>

                { _package && products?.length > 0 &&
                <>
                <h1>Schedule</h1>
                
                { _package.pk_schedule?.map((ps, i) => {

                    return (
                        <Card key={i} title={`Month ${i+1}`} style={{marginBottom: 30}}>
                            
                            { ps.items.map((item, x) => {

                                let p = products.find((_p) => _p.pr_id === item.pr_id)
                                
                                return (
                                    <div key={x} className="productItem">
                                        <Row gutter={16} align={"middle"}>
                                            <Col span={7}>
                                                {p.pr_name}
                                            </Col>
                                            <Col span={5}>
                                                {p.pr_category}
                                            </Col>
                                            <Col span={6}>
                                                { p.pr_level === "Page" ?
                                                <Input 
                                                    onBlur={(e)=> changeProductPage(i, x, e)} 
                                                    prefix={"Page No:"} 
                                                    type="number" 
                                                    style={{width: 200}}
                                                    value={item.page}
                                                    // min={1}
                                                    // max={_package.pk_pages}
                                                    onChange={(e) => onProductPageChange(i, x, e)}
                                                />
                                                :
                                                p.pr_level}
                                            </Col>
                                            <Col span={4}>
                                                {p.pr_minutes} Minutes
                                            </Col>
                                            <Col span={2}>
                                                <Button onClick={() => removeLine(i, x)}>X</Button>
                                            </Col>
                                        </Row>

                                        
                                    </div>
                                )
                            })}
                            

                                { ps.items.length > 0 &&
                                    <div className="productItem">
                                        <Row gutter={16}>
                                            <Col span={20}>
                                                
                                            </Col>
                                            <Col span={4}>
                                                <strong>
                                                { ps.items.reduce(
                                                    (sum, obj) => {

                                                        let px = products.find((_p) => _p.pr_id === obj.pr_id)
                                                        return +sum + +px.pr_minutes
                                                    }, 0)} Minutes
                                                </strong>
                                            </Col>
                                        </Row>
                                    </div> }

                            <div style={{marginTop: 30}}>
                                <Button style={{width: '100%'}} onClick={() => addProduct(i) }>Add product</Button>
                            </div>
                        </Card>
                    )
                })}
                

                <div style={{textAlign: 'right'}}>
                    <Button onClick={() => addMonth() }>Add month</Button>
                </div>
                </> }
            </Form>

            <Modal
                open={showModal}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                title="Add product"
                width={800}
            >
                <>

                <Input value={productSearch} onChange={(e) => setProductSearch(e.target.value)} style={{marginBottom: 10}}/>

                <Table 
                    dataSource={products.filter((p) => p.pr_name.toLowerCase().includes(productSearch.toLowerCase()))} 
                    rowKey="pr_id" 
                    columns={cols} 
                    pagination={false}
                    onRow={onRowClick}
                />
            </>
            </Modal>
        </>
    )
}

export default AddPackage;