import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { useCampaigns } from '../../System/Global/DataHooks/useCampaigns';
import { usePackages } from '../../System/Global/DataHooks/usePackages';
import { useGlobal } from '../../System/Global/GlobalProvider';
import SiteConfig from '../../System/SiteConfig';
import { checkUrl } from '../../System/Api/API';

export const AddCampaign = () => {

    const navigate = useNavigate();
    const [ form ] = Form.useForm();
    const { saveCampaign } = useCampaigns();
    const { packages } = usePackages();    
    const { setCampaign } = useGlobal();

    const saveCampaignDetails = async (values) => {

        let data = {
            ...values, 
            cm_target_pages: [],
        }

        let cm_id = await saveCampaign(data);

        localStorage.setItem(`${SiteConfig.cookieName}-cm_id`, cm_id);

        setCampaign({cm_id, ...data})

        navigate(`/dashboard`)
        
    }

    const validateUrl = async (_, url) => {
        if (!url) {
            // If the URL is empty, you might want to allow it or show a different message
            return Promise.reject(new Error('URL is required'));
        }
    
        try {
            const responseStatus = await checkUrl('https://' + url);
            if (responseStatus === 200) {
                // If the URL is valid
                return Promise.resolve();
            } else {
                // If the URL is not valid
                return Promise.reject(new Error('Please enter a valid URL'));
            }
        } catch (error) {
            // If there's an error (e.g., network error), you can handle it accordingly
            return Promise.reject(new Error('URL validation failed'));
        }
    };

    return (
        <>
        <h1>New Campaign</h1>
            <Form onFinish={saveCampaignDetails} form={form} layout="vertical" initialValues={{cm_pk_id: '350566e0-dae0-11ee-b9b8-9fd0b194d652'}}>
                <Row gutter={16}>
                    <Col span={12}>
                        
                        <Card style={{marginBottom: 30}}>
                        <Form.Item name="cm_name" label="Campaign name" rules={[
                            {
                                required: true,
                                message: 'Please enter a campaign name!',
                            }]}>
                            <Input />
                        </Form.Item>
                    
                        <Form.Item name="cm_url" label="Website URL" rules={[
                            {
                                required: true,
                                validator: validateUrl,
                            }]}
                            validateTrigger='onBlur'
                            >
                                
                            <Input />
                        </Form.Item>

                        <Form.Item name="cm_sap_id" label="SAP ID" rules={[
                            {
                                required: true,
                                message: 'Please enter a SAP ID!',
                            }]}>
                            <Input />
                        </Form.Item>
                    
                        <Form.Item name="cm_pk_id" label="Package">
                            <Select>
                                { packages.map((p) => <Select.Option value={p.pk_id}>{p.pk_name}</Select.Option> )}
                            </Select>
                        </Form.Item>

                        </Card>
                    </Col>

                    <Col span={12}>
                        
                        <Card style={{marginBottom: 30}}>
                        <Form.Item name="cm_industry" label="Industry" rules={[
                            {
                                required: true,
                                message: 'Please enter am industry!',
                            }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="cm_keywords" label="Keywords (comma seperated)" rules={[
                            {
                                required: true,
                                message: 'Please enter keywords!',
                            }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="cm_description" label="Business description" rules={[
                            {
                                required: true,
                                message: 'Please enter a description!',
                            }]}>
                            <Input.TextArea rows={5} />
                        </Form.Item>

                        <Form.Item style={{textAlign: 'right'}}>
                            <Button style={{marginRight: 5}} onClick={() => navigate('/campaigns/list')} htmlType='button'>Cancel</Button>
                            <Button type="primary" htmlType='submit'>Submit</Button>
                        </Form.Item>

                        </Card>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AddCampaign;