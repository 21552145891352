import React from 'react';
import { Layout, Menu, Dropdown, Avatar, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useGlobal } from '../Global/GlobalProvider';
import {
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import MainMenu from './MainMenu';
import SiteConfig from '../SiteConfig';

const { Header, Sider, Content } = Layout;

const MainLayout = ({ children }) => {

  const { logout, campaign, setCampaign, masterMenu, setMasterMenu, UserData } = useGlobal();
  const navigate = useNavigate();
  // const [ r, resetMenu ] = useState([]);

  const userMenu = (
    <Menu style={{width: 300}}>
      <Menu.Item key="2" onClick={() => logout()} icon={<LogoutOutlined/>}>
        Log out
      </Menu.Item>
    </Menu>
  );
  
  const renderMainSider = () => {

    return (
      <Sider
        width={300}

        style={{ position: 'fixed', height: '100vh', overflow: 'auto', paddingTop: 60 }}
        className="site-layout-background"
      >
        <MainMenu/>
      </Sider>
    )
  }

  return (
    UserData &&
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="site-layout-background" 
          style={{ 
            padding: '0 16px', 
            display: 'flex', 
            justifyContent: 'space-between', 
            position: 'fixed', 
            width: `calc(100%)`, // Adjusting for the Sider width
            zIndex: 1 
          }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 30, cursor: 'pointer' }} >
              {/* <div style={{ marginRight: 30, cursor: 'pointer' }} onClick={UserData.us_role === "User" ? () => {} : () => { localStorage.setItem(`${SiteConfig}-menu`, masterMenu === "User" ? "Admin" : "User"); setMasterMenu(masterMenu === "User" ? "Admin" : "User") } }> */}
                {/* <img 
                  onClick={UserData.us_role === "User" ? () => {} : () => { localStorage.setItem(`${SiteConfig}-menu`, masterMenu === "User" ? "Admin" : "User"); setMasterMenu(masterMenu === "User" ? "Admin" : "User") } }
                  style={{ width: 45, marginTop: 25 }} src="/images/Yell-Agency-Logo.webp" alt=""/> */}
                <div style={{
                  backgroundColor: '#555',
                  width: 40,
                  height: 40,
                  borderRadius: '50%'
                }}></div>
              </div>
              <div>
              
              { campaign && masterMenu === "User" &&
              <div 
              onClick={() => { setCampaign(null); navigate('/campaigns/list')} }
              style={{
                backgroundColor: '#EFF2F5', 
                width: 400,
                height: 45,
                borderRadius: 10,
                display: 'flex',
                alignItems: "top",
                alignContent: "top",
                paddingLeft: 15,
                cursor: 'pointer',
              }}>
                <div style={{fontSize: 16, lineHeight: 3}}>
                { campaign.cm_name}
                </div>
              </div> }
              {/* <Input 
                  style={{ width: 500 }} 
                  placeholder={"Search for anything and press enter..."} 
                  value="Bigg Ltd"
                  
                /> */}
              </div>
            </div>
        {/* <Title level={3} style={{ lineHeight: '64px', margin: 0, color: '#fff' }}>
        
        </Title> */}
        <Dropdown overlay={userMenu}>
          <Button type="transparent" style={{ marginRight: '0px', marginTop: 15, display: 'flex', alignItems: 'center' }}>
              <Avatar size={40} style={{ backgroundColor: '#87d068', marginRight: '0px' }} 
              icon={UserData.us_image ? <img src={UserData.us_image} alt="user"/>  : <UserOutlined />} 
              />
          </Button>
      </Dropdown>
      </Header>

      { campaign ?
      
      <>
        { renderMainSider() }
        <Layout style={{ marginLeft: 300 }}>
      
        <Content style={{ padding: '24px', marginTop: 64, minHeight: 280 }}>  {/* Added marginTop to accommodate the fixed Header */}
            {/* <Outlet/> */}
            { children }
          </Content>
        </Layout>
      </>
      :
      <Layout style={{  }}>
        <Content style={{ padding: '24px', marginTop: 64, minHeight: 280 }}>  {/* Added marginTop to accommodate the fixed Header */}
            {/* <Outlet/> */}
            { children }
          </Content>
      </Layout>
      }
    </Layout>
  );
}

export default MainLayout;
