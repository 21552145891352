import { Card, Col, DatePicker, Row } from "antd"
import { useGlobal } from "../../System/Global/GlobalProvider"
import SiteConfig from "../../System/SiteConfig";
import dayjs from 'dayjs'
import { useEffect, useState } from "react";
import { useTasks } from "../../System/Global/DataHooks/useTasks";
import { useCampaigns } from "../../System/Global/DataHooks/useCampaigns";
import { useNavigate } from "react-router-dom";
import { get } from "../../System/Api/API";

export const AdminDashboard = () => {

    const navigate = useNavigate();
    const { UserData } = useGlobal();
    const { fetchTasksByDate } = useTasks();
    const { fetchCampaigns } = useCampaigns();
    const [ tasks, setTasks ] = useState([])
    const [ campaigns, setCampaigns ] = useState([])
    const [ dates, setDates ] = useState(
        {
            from: dayjs(localStorage.getItem(`${SiteConfig}-fromdate`) || dayjs().startOf('month')).format('YYYY-MM-DD'),
            to: dayjs(localStorage.getItem(`${SiteConfig}-todate`) || dayjs().add('2', 'months').endOf('month')).format('YYYY-MM-DD')
        }
    )

    useEffect( () => {

        getTasks();
        getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates])

    

    const getTasks = async () => {

        let _tasks = await fetchTasksByDate(dates.from, dates.to);
        setTasks(_tasks)
    }
    const getCampaigns = async () => {

        let _campaigns = await fetchCampaigns(dates.from, dates.to);
        setCampaigns(_campaigns)
    }

    const DbCard = ({title, score, description, onClick}) => {

        return (
            <Col span={6}>
                <Card className="dbCard" hoverable onClick={onClick ? onClick : () => {} }>
                    <h3>{title}</h3>
                    <div className="dbStat">
                        {!isNaN(score) ? score : 0}
                    </div>
                    <div className="dbDesc">
                        {description}
                    </div>
                </Card>
            </Col>
        )
    }

    const changeDateRange = async (e) => {

        let fromDate = dayjs(e[0]).format('YYYY-MM-DD')
        let toDate = dayjs(e[1]).format('YYYY-MM-DD')

        localStorage.setItem(`${SiteConfig}-fromdate`, fromDate);
        localStorage.setItem(`${SiteConfig}-todate`, toDate);

        setDates({from: fromDate, to: toDate});

    }

    return (
        
        <div>
             <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Welcome, {UserData.us_first_name}</h1>
                </div>
                <div> 
                <DatePicker.RangePicker
                    onChange={changeDateRange} 
                    format={'DD/MM/YYYY'}
                    defaultValue={[dayjs(dates.from), dayjs(dates.to)]}
                    
                    ranges={
                        {
                            'Last month' : [
                                dayjs(dayjs().startOf('month').add(-1, 'month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), 
                                dayjs(dayjs().endOf('month').add(-1, 'month').endOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                            ],
                            'This month' : [
                                dayjs(dayjs().startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), 
                                dayjs(dayjs().endOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                            ],
                            'Next month' : [
                                dayjs(dayjs().add('1', 'month').startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), 
                                dayjs(dayjs().add('1', 'month').endOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                            ],
                            'Today +2 months' : [
                                dayjs(dayjs().startOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), 
                                dayjs(dayjs().add('2', 'months').endOf('month').format('YYYY-MM-DD'), 'YYYY-MM-DD')
                            ],
                        }
                    }
                />
                </div>
            </div>
            
            <Row gutter={16}>
                <DbCard title="Total Tasks" score={
                    tasks.length
                } description="Tasks number of tasks" />
                <DbCard title="Unassigned Tasks" onClick={() => { navigate('/tasks/list/all/unassigned') } } score={
                    tasks.filter((t) => !t.tk_us_id).length
                } description="Tasks that need to be assigned" />
                <DbCard title="Outstanding Tasks" onClick={() => { navigate('/tasks/list/all/outstanding') } } score={
                    tasks.filter((t) => t.tk_us_id && !t.tk_completed).length
                } description="Tasks assigned but outstanding" />
                <DbCard title="Completed Tasks" onClick={() => { navigate('/tasks/list/all/completed') } } score={
                    tasks.filter((t) => t.tk_completed).length
                } description="Completed tasks" />


                <DbCard title="Active Campaigns" onClick={() => { navigate('/campaigns/admin/list/active') } } score={
                    campaigns.filter((c) => c.cm_status === "Live" && c.cm_onboarded).length
                } description="Total active campaigns" />
                <DbCard title="Pending Campaigns" onClick={() => { navigate('/campaigns/admin/list/pending') } } score={
                    campaigns.filter((c) => c.cm_status === "Live" && !c.cm_onboarded).length
                } description="Campaigns awaiting onboarding" />
                <DbCard title="Paused Campaigns" onClick={() => { navigate('/campaigns/admin/list/paused') } } score={
                    campaigns.filter((c) => c.cm_status === "Paused").length
                } description="Campaigns marked as paused" />
                <DbCard title="Cancelled Campaigns" onClick={() => { navigate('/campaigns/admin/list/cancelled') } } score={
                    campaigns.filter((c) => c.cm_status === "Cancelled").length
                } description="Campaigns marked as cancelled" />
                
                

                
            </Row>
        </div>
    )
}