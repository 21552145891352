import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';
import { useCampaigns } from '../../System/Global/DataHooks/useCampaigns';
import { useGlobal } from '../../System/Global/GlobalProvider';
import SiteConfig from '../../System/SiteConfig';
import { get, getOld, save } from '../../System/Api/API';
import { escapeNested, parseStringifiedArrays, unescapeNested } from '../../System/Global/unescapeArray';
import { useTasks } from '../../System/Global/DataHooks/useTasks';
import { usePackages } from '../../System/Global/DataHooks/usePackages';
import { useProducts } from '../../System/Global/DataHooks/useProducts';

export const BulkGenerateTasks = () => {

    const { setCampaign } = useGlobal();
    const { fetchTasks, saveTask } = useTasks();
    const { packages } = usePackages();
    const { products } = useProducts();
    const { saveCampaign } = useCampaigns();
    const { fetchCampaigns } = useCampaigns();
    const [ campaigns, setCampaigns ] = useState([])
    const navigate = useNavigate();
    
    useEffect( () => {

        ( async () => {

            if (packages.length > 0 && products.length > 0) {

                let _campaigns = await get('campaigns', null, {cm_bigg_id: 'asc'})
                setCampaigns(_campaigns);
            }
            
                
                
            
        })()

        return () => {
            setCampaigns([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packages, products])

    const generateTasks = async (campaign) => {

        // get the campaign target pages

        const _targetPages = campaign.cm_target_pages;

        // get the package
        const _package = packages.find((p) => p.pk_id === campaign.cm_pk_id)

        for await (let [index, ps] of _package.pk_schedule.entries()) {

                for await (let psi of ps.items) {

                    let product = products.find((p) => p.pr_id === psi.pr_id);
    
                    
                    let taskData = {
                        tk_cm_id:       campaign.cm_id,
                        tk_pr_id:       product.pr_id,
                        tk_due:         dayjs().add(index, 'months').format(`YYYY-MM-${dayjs(campaign.cm_created).format('DD')}`),
                        tk_month:       index+1,
                        tk_pk_id:       _package.pk_id,
                        tk_target_name:      product.pr_level === "Page" ? _targetPages[psi.page -1].name : "",
                        tk_target_url:      product.pr_level === "Page" ? _targetPages[psi.page -1].url : "",
                    }
    
                    await saveTask(taskData);
    
                }

            

            
            
        }


    }

    let cols = [
        {
            title: 'Name',
            render: item => `${item.cm_name}`,
            key: 'cm_name',
        },
        {
            title: 'URL',
            render: item => `${item.cm_url}`,
            key: 'cm_url',
        },
        {
            title: 'Created',
            key: 'date',
            render: item => dayjs(item.cm_created).format('DD/MM/YYYY')
        },
        {
            title: 'SAP ID',
            render: item => `${item.cm_sap_id}`,
            key: 'cm_sap_id',
        }
        
    ]


    const createTasks = async () => {

        for await (let c of campaigns) {

            console.log('transferring campaign')
            console.log(c)

            if (c.cm_pk_id) {
                await generateTasks(c)

            }
            


        }
    }
    
   
    return (
        campaigns &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Campaigns</h1>
                </div>
                <div>
                    <Button onClick={createTasks} type="primary">Start</Button>
                </div>
            </div>
            <Table 
                dataSource={campaigns} 
                rowKey="cm_id" 
                columns={cols} 
                pagination={false}
            />
        </div>
    )
}

export default BulkGenerateTasks;