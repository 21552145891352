import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Input, Row, Table, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useCampaigns } from '../../System/Global/DataHooks/useCampaigns';
import { useGlobal } from '../../System/Global/GlobalProvider';
import SiteConfig from '../../System/SiteConfig';
import { get, remove, save } from '../../System/Api/API';
import { useInvoicing } from '../../System/Global/DataHooks/useInvoicing';

export const InvoiceList = () => {

    const { fetchInvoices } = useInvoicing();
    const [ records, setRecords ] = useState([])
    const [ filteredRecords, setFilteredRecords ] = useState([])
    const navigate = useNavigate();
    const [ searchText, setSearchText ] = useState("")
    const [ totals, setTotals ] = useState({})
    
    useEffect( () => {

        ( async () => {

                
            
                let _records = [];
                _records = await fetchInvoices()

                setRecords(_records)
                setFilteredRecords(_records)

                
        })()

        return () => {
            setRecords([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    useEffect( () => {


        if (records.length > 0) {
            setFilteredRecords(records.filter((r) => r.inv_start.toLowerCase().includes(searchText.toLowerCase())))
        }

    }, [searchText])

    let cols = [
        {
            title: 'Start date',
            render: item => `${dayjs(item.inv_start).format('Do MMM YYYY')}`,
            key: 'inv_start',
        },
        {
            title: 'Finish date',
            render: item => `${dayjs(item.inv_end).format('Do MMM YYYY')}`,
            key: 'inv_end',
        },
        {
            title: 'Campaigns',
            render: item => `${item.inv_campaigns.length}`,
            key: 'campaigns',
        },
        {
            title: 'Total Amt',
            render: item => `${item.inv_total}`,
            key: 'total',
        },
    ]


    const onRowClick = (r) => {
        return {
            onClick: event => {

                navigate('/billing/' + r.inv_id)
                
            },
        };
    };

    
   
    return (
        records &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Invoices</h1>
                </div>
                <div>
                    <Button onClick={() => navigate('/billing/add')} type="primary">New invoice</Button>
                </div>
            </div>
            
            <div>
                {/* <Card>
                <Input value={searchText} onChange={(v) => setSearchText(v.target.value) } placeholder="Search by invoice date"/>
                </Card> */}
                <br/>
            </div>
            <Table 
                dataSource={filteredRecords} 
                rowKey="inv_id" 
                columns={cols} 
                pagination={false}
                onRow={onRowClick}
            />
        </div>
    )
}

export default InvoiceList;