import React, { useEffect, useState } from 'react';
import { Menu } from 'antd'; // import necessary components
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FiFolder, FiHome, FiList, FiMail, FiShoppingCart } from 'react-icons/fi';
import { campaignMenu } from './CampaignMenu';
import { ProductMenu } from './ProductMenu';
import { PackageMenu } from './PackageMenu';
import { lighthouseMenu } from './LighthouseMenu';
import { useGlobal } from '../Global/GlobalProvider';
import { TasksMenu } from './TasksMenu';
import { UsersMenu } from './UsersMenu';
import { FilesMenu } from './FilesMenu';

const MainMenu = ({ resetMenu }) => {

     const [currentMenu, setCurrentMenu] = useState([]); // start with main menu
     const [previousMenus, setPreviousMenus] = useState([]); // to keep track of navigation
     const [ menuOptions, setMenuOptions ] = useState([])
     const { masterMenu, UserData } = useGlobal();

     useEffect( () => {

          if (UserData?.us_id) {

               const mo = [
                    
                   lighthouseMenu(),
                    {
                         key: "3",
                         link: "/tasks/list",
                         label: 'Tasks',
                         icon: <FiList/>,
                         
                    },
                    FilesMenu(),
                    {
                         key: "10",
                         link: "/email",
                         label: 'Emails',
                         icon: <FiMail/>,
                         
                    },
                    campaignMenu(),
                    
                    
                    // ... other main menu options
               ]

               const adminMo = [
                    {
                         key: "12",
                         link: "/campaigns/list",
                         label: 'Campaigns',
                         icon: <FiHome/>,
                         divider: true,
                    },
                    {
                         key: "0.0",
                         title: "Admin",
                         noArrow: true,
                    },
                    
                    
                    // DashboardMenu(),
                    
                    ProductMenu(),
                    PackageMenu(),
                    TasksMenu(),
                    {
                         key: "11",
                         link: "/billing",
                         label: 'Billing',
                         icon: <FiShoppingCart/>,
                         
                    },
                    {
                         key: "20",
                         link: "/changelog",
                         label: 'Change Log',
                         icon: <FiList/>,
                         
                    },
                    UsersMenu(),
               ]



               setMenuOptions(masterMenu === "User" ? mo : adminMo)
               setCurrentMenu(masterMenu === "User" ? mo : adminMo)


          }
          

     }, [UserData, resetMenu, masterMenu])

     const findMenuOption = (menuOptions, selectedKey) => {
          for (let option of menuOptions) {
            if (option.key === selectedKey) {
              return option;
            }
            if (option.innerMenu) {
              const foundOption = findMenuOption(option.innerMenu, selectedKey);
              if (foundOption) {
                return foundOption;
              }
            }
          }
          return null;
        };
        
        const handleMenuClick = (selectedKey) => {
          const selectedOption = findMenuOption(menuOptions, selectedKey);
        
          
          if (selectedOption && selectedOption.innerMenu) {
            setPreviousMenus([...previousMenus, currentMenu]); // push current menu to history
            setCurrentMenu(selectedOption.innerMenu); // set inner menu as current
          }
        };

     const handleBackClick = () => {
          const lastMenu = previousMenus.pop(); // get the last menu from history
          setCurrentMenu(lastMenu); // set it as the current menu
          setPreviousMenus([...previousMenus]);
     }

     return (
     <div>
          
          <Menu mode="inline" style={{paddingTop: 15}} onClick={(e) => handleMenuClick(e.key)}>
          {currentMenu.map(option => { 
               if (option.title) {
                    return (
                         <div key={option.key} style={{paddingLeft: 20, marginTop: 30, marginBottom: 30}}>
                         <h2>
                              { !option.noArrow && 
                              <ArrowLeftOutlined onClick={handleBackClick} style={{cursor: 'pointer', marginRight: 30}}/>  }
                              {option.title}
                         </h2>
                         </div>
                    )
               } else {
                    return (
                         <>
                         <Menu.Item key={option.key} style={{paddingLeft: 10}} icon={
                              option.profile ? <div className="profilePic">{option.icon}</div> : option.icon}>
                         { option.link ?
                         <Link to={option.link}>{option.label}</Link>
                         : option.label }
                         
                         </Menu.Item>
                         {option.divider && <hr color="#f1f1f1"/>}
                         </>
                    )}
               }
          )}
          </Menu>
     </div>
  );
}

export default MainMenu;
