import { get, save } from '../../Api/API';
import { escapeNested, stringifyArraysInObject, unescapeNested } from '../unescapeArray';

const useInvoicing = () => {

    const fetchInvoices = async () => {
        try {

            let results = await get(
                'invoices', 
                null, 
                {inv_start: 'desc'},
            )

            return await unescapeNested(results);

        } catch (err) {

            return [];
        }
    };

    const fetchInvoice = async (inv_id) => {
        try {

            let results = await get('invoices', {inv_id}, {inv_start: 'desc'})

            return await unescapeNested(results[0]);

        } catch (err) {

            return [];
        }
    };

    const saveInvoice = async (newItems, inv_id) => {

        newItems = await escapeNested(newItems);
        newItems = await stringifyArraysInObject(newItems)

        if (inv_id) {

            await save('invoices', newItems, {inv_id: inv_id})
        } else {
            inv_id = await save('invoices', newItems)
        }

        return inv_id

    }

    

    return { fetchInvoices, saveInvoice, fetchInvoice };
};


export { useInvoicing };
