import { useNavigate } from 'react-router-dom';
import { Table } from 'antd';
import { useProducts } from '../../System/Global/DataHooks/useProducts';

export const ProductList = () => {

    const { products } = useProducts()

    const navigate = useNavigate();
    
    let cols = [
        {
            title: 'Name',
            render: item => `${item.pr_name}`,
            key: 'pr_name',
        },
        {
            title: 'Category',
            render: item => `${item.pr_category}`,
            key: 'pr_category',
        },
        {
            title: 'Level',
            render: item => `${item.pr_level}`,
            key: 'level',
        },
        {
            title: 'Minutes',
            render: item => `${item.pr_minutes}`,
            key: 'pr_minutes',
        },
        
    ]


    const onRowClick = (record) => {
        return {
            onClick: event => {

                navigate('/products/view/' + record.pr_id)
            },
        };
    };

    
   
    return (
        products &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Products</h1>
                </div>
            </div>
            <Table 
                dataSource={products} 
                rowKey="pr_id" 
                columns={cols} 
                pagination={false}
                onRow={onRowClick}
            />
        </div>
    )
}

export default ProductList;