import { Tabs} from 'antd';
import CampaignDetails from './Components/CampaignDetails';
import TargetPages from './Components/TargetPages';

export const ViewCampaign = () => {


    return (
        <>
        <h1>Campaign Settings</h1>

        <Tabs defaultActiveKey='1'>
            <Tabs.TabPane key={"1"} tab="Details">
                <CampaignDetails/>
            </Tabs.TabPane>
            <Tabs.TabPane key={"2"} tab="Target Pages">
                <TargetPages/>
            </Tabs.TabPane>
        </Tabs>
        
        </>
    )
}

export default ViewCampaign;