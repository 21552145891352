import { Alert } from "antd"
import { useState } from "react"
import { useGlobal } from "../../System/Global/GlobalProvider"
import LighthouseList from "../Lighthouse/LighthouseList"
import { FileViewer } from "../SiteWide/FileViewer"
import CampaignDetails from "./Components/CampaignDetails"
import Emails from "./Emails/Emails"
import TasksList from "./Tasks/TasksList"


export const AdminViewCampaign = () => {

        const [ screen, setScreen ] = useState(1)
        const { campaign } = useGlobal()

        console.log('campaign', campaign)

        const menuOptions = [
                { id:     1, name:   "Details" },
                { id:     2, name:   "Lighthouse" },
                { id:     3, name:   "Tasks" },
                { id:     4, name:   "Files" },
                { id:     5, name:   "Emails" }
        ]

        const menuOption = (option) => {

                return (
                        <li className={option.id === screen && "active"} onClick={() => setScreen(option.id) }>{option.name}</li>
                )
        }

        return (
                campaign &&
                <div>
                        <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>
                                        <h1>{campaign.cm_name}</h1>
                                        {campaign.cm_notes && campaign.cm_notes.length > 0 &&
                                        <Alert style={{marginBottom: 10}} type="warning" description={campaign.cm_notes}/> }
                                </div>
                                <div>
                                        <ul className="menu">
                                                { menuOptions.map((o, i) => menuOption(o) )}
                                        </ul>
                                </div>
                                
                        </div>
                        <div>
                                { screen === 1 && <CampaignDetails /> }
                                { screen === 2 && <LighthouseList /> }
                                { screen === 3 && <TasksList /> }
                                { screen === 4 && <FileViewer /> }
                                { screen === 5 && <Emails /> }
                        </div>
                </div>
        )
}