import React, { createContext, useContext, useState, useEffect } from 'react';
import bcrypt from 'bcryptjs';
import { save, get, globalGet } from '../Api/API';
import { SiteConfig } from '../../System/SiteConfig';
import { unescapeArray } from './unescapeArray';
import { useCampaigns } from './DataHooks/useCampaigns';

const GlobalContext = createContext();

export const useGlobal = () => {
    return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
    const [title, setTitle] = useState('Your CRM Name');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [ UserData, setUserData ] = useState({})
    const [ campaign, setCampaign ] = useState();
    const { fetchCampaign } = useCampaigns();
    const [ masterMenu, setMasterMenu ] = useState(localStorage.getItem(`${SiteConfig}-menu`) || "User")

    useEffect(() => {

        ( async () => {

            const userId = localStorage.getItem(`${SiteConfig.cookieName}-us_id`);
            
        
            if (userId) {

            
                let users = await get('users', {
                    us_id:       userId,
                })

                if (users.length > 0) {

                    setData(users[0])
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }

                
            }
            
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setData = async (user) => {

        const campaignId = localStorage.getItem(`${SiteConfig.cookieName}-cm_id`);

        if (campaignId) {
            let campaign = await fetchCampaign(campaignId);
            setCampaign(campaign)
        } 

        
        setUserData(user);

        if (user.us_role === "Admin") {
            setMasterMenu("Admin")
        } else {
            setMasterMenu("User")
        }
        
        localStorage.setItem(`${SiteConfig.cookieName}-us_id`, user.us_id);

    }

    // Authenticate user and set the isAuthenticated state to true
    const authenticate = async (username, password, auto = false, us_id = null) => {
        
        
        let users = await globalGet('users', {
            us_email:       username,
        })

        console.log(users)

        users = unescapeArray(users);

        console.log(users)

        if (users.length > 0) {

            let user = users[0];

            let isMatch = false;

            if (auto) {

                isMatch = true;
            } else {
                isMatch = bcrypt.compareSync(password, user.us_password_hash);
            }

            if (isMatch) {

                
                setIsAuthenticated(true);
                setData(user)
                return true;

            } else {
                setIsAuthenticated(false);
                return false;
            }

        } else {

            setIsAuthenticated(false);
            return false;
        }
        
    };

    const signup = async (username, password, first_name, last_name, motherLodge, province, isNewLodge = false) => {
        // Send user data to your backend API

        const salt = bcrypt.genSaltSync(10);
        const hashedPassword = bcrypt.hashSync(password, salt);

        let user = {
            us_cl_id:           "559c6da0-6f3c-11ee-85a0-2b5f4feaabcf",
            us_email:           username,
            us_password_hash:   hashedPassword,
            us_role:            'Admin',
            us_status:          'Active',
            us_first_name:      escape(first_name),
            us_last_name:       escape(last_name),
            us_career:          JSON.stringify([]),
            us_motherLodge:     escape(motherLodge),
            us_province:        province,
            us_banner:           '/images/profile-placeholder.jpeg',
            us_image:         '/images/masonhub-icon-blue.png'
        }
        let us_id = await save('users', user)

        // if its a new lodge then add this user as admin of that lodge.
        if (isNewLodge) {

            

            await save('lodges', {

                lg_cl_id: "559c6da0-6f3c-11ee-85a0-2b5f4feaabcf",
                lg_likes: JSON.stringify([{us_id: us_id, type: "Admin"}])
    
            }, {lg_id: motherLodge})

            
        }
        


        user.us_id = us_id

        setIsAuthenticated(true);
        await setData(user)
        return true;
        

    };

    const logout = () => {

        localStorage.removeItem(`${SiteConfig.cookieName}-userId`);

        setIsAuthenticated(false);
    };

    const checkAuth = () => {
        return isAuthenticated;
    };

    return (
        <GlobalContext.Provider value={{ masterMenu, setMasterMenu, title, setTitle, authenticate, signup, logout, checkAuth, UserData, campaign, setCampaign }}>
            {children}
        </GlobalContext.Provider>
    );
};
