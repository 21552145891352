import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';
import { useCampaigns } from '../../System/Global/DataHooks/useCampaigns';
import { useGlobal } from '../../System/Global/GlobalProvider';
import SiteConfig from '../../System/SiteConfig';
import { getOld, save } from '../../System/Api/API';
import { escapeNested, parseStringifiedArrays, unescapeNested } from '../../System/Global/unescapeArray';

export const TransferCampaigns = () => {

    const { setCampaign } = useGlobal();
    const { fetchCampaigns } = useCampaigns();
    const [ campaigns, setCampaigns ] = useState([])
    const navigate = useNavigate();
    
    useEffect( () => {

        ( async () => {
            
                let _campaigns = await getOld('campaigns', {status: "E"})
                
                for await (let c of _campaigns) {

                    console.log('transferring campaign')
                    console.log(c)

                    let pkid = "";

                    if (c.product === "SEO") { pkid = "350566e0-dae0-11ee-b9b8-9fd0b194d652" }
                    if (c.product === "SEO Intermediate") { pkid = "9d7d2d20-dae0-11ee-b9b8-9fd0b194d6522" }
                    if (c.product === "SEO Multi-Page") { pkid = "9d7d2d20-dae0-11ee-b9b8-9fd0b194d6524" }
                    if (c.product === "SEO Premium") { pkid = "9d7d2d20-dae0-11ee-b9b8-9fd0b194d6523" }
                    if (c.product === "SEO Starter") { pkid = "9d7d2d20-dae0-11ee-b9b8-9fd0b194d652" }
                    if (c.product === "SEO Starter Bolt On") { pkid = "9d7d2d20-dae0-11ee-b9b8-9fd0b194d652" }

                    let keywords = "";

                    let k = JSON.parse(c.keywords);

                    console.log(k)

                    if (k.kw1 && k.kw1.length > 0) { keywords += k.kw1 }

                    for (let i = 1; i < 21; i++) {

                        if (k[`kw${i}`] && k[`kw${i}`].length > 0) { keywords += ',' + k[`kw${i}`] }

                    }

                    let data = {
                        old_id:             c.key,
                        cm_sap_id:          c.thomson_ref,
                        cm_service_id:      c.service_id,
                        cm_order_no:        c.order_no,
                        cm_name:            c.name,
                        cm_url:             c.target_url,
                        cm_pk_id:           pkid,
                        cm_created:         dayjs(c.created).format('YYYY-MM-DD'),
                        cm_description:     escape(c.citation),
                        cm_keywords:        escape(keywords),
                        cm_onboarded:       1,
                        cm_status:          "Live",
                        cm_website:         c.tl_website,
                        cm_location:        c.seo_location,
                        cm_email:           c.email,
                        cm_customer_key:    c.customer_key,
                        cm_target_pages:    JSON.stringify([{index: 1, url: c.target_url, name: 'target'}])

                    }

                    
                    console.log(data)

                    await save('campaigns', data)

                }


                setCampaigns(_campaigns);
                
            
        })()

        return () => {
            setCampaigns([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let cols = [
        {
            title: 'Name',
            render: item => `${item.cm_name}`,
            key: 'cm_name',
        },
        {
            title: 'URL',
            render: item => `${item.cm_url}`,
            key: 'cm_url',
        },
        {
            title: 'Created',
            key: 'date',
            render: item => dayjs(item.cm_created).format('DD/MM/YYYY')
        },
        {
            title: 'SAP ID',
            render: item => `${item.cm_sap_id}`,
            key: 'cm_sap_id',
        },
        {
            title: 'Package',
            render: item => `${item.pk_name}`,
            key: 'cm_pk_id',
        },
        
    ]


    const onRowClick = (record) => {
        return {
            onClick: event => {

                // select campign

                localStorage.setItem(`${SiteConfig.cookieName}-cm_id`, record.cm_id);
                setCampaign(record);

                if (record.cm_onboarded) {
                    navigate('/lighthouse/list')
                } else {
                    navigate('/campaign/view')
                }
                
            },
        };
    };

    
   
    return (
        campaigns &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Campaigns</h1>
                </div>
                <div>
                    <Button onClick={() => navigate('/campaigns/add')} type="primary">New campaign</Button>
                </div>
            </div>
            <Table 
                dataSource={campaigns} 
                rowKey="cm_id" 
                columns={cols} 
                pagination={false}
                onRow={onRowClick}
            />
        </div>
    )
}

export default TransferCampaigns;