import { get, raw, save } from '../../Api/API';
import { useGlobal } from '../GlobalProvider';
import { escapeNested, stringifyArraysInObject, unescapeNested } from '../unescapeArray';
import axios from "axios";

const useLighthouse = () => {

    const { campaign } = useGlobal();

    const runLighthouseReport = async (target_name, target_url) => {
    
        return new Promise ((resolve, reject) => {

            console.log("running report")
    
            const post_array = [];
            post_array.push({
                "url": unescape(target_url),
                "tag": "Bigg",
            });
            axios({
                method: 'post',
                url: 'https://api.dataforseo.com/v3/on_page/lighthouse/live/json',
                auth: {
                    username: 'john@bigg.co.uk',
                    password: 'sj5jTajF32kElXC1'
                },
                data: post_array,
                headers: {
                    'content-type': 'application/json'
                }
            }).then(async (response) => {
                
                var result = response['data']['tasks'];
                
                console.log('Response', response)
    
                let lhData = {
                    lh_cm_id:               campaign.cm_id, 
                    lh_target_name:         target_name,
                    lh_target_url:          target_url, 
                    lh_s:                   result[0].result[0].categories.seo.score ? (result[0].result[0].categories.seo.score * 100).toFixed(0) : 0,
                    lh_p:                   result[0].result[0].categories['performance'].score ? (result[0].result[0].categories['performance'].score * 100).toFixed(0) : 0,
                    lh_a:                   result[0].result[0].categories['accessibility'].score ? (result[0].result[0].categories['accessibility'].score * 100).toFixed(0) : 0,
                    lh_b:                   result[0].result[0].categories['best-practices'].score ? (result[0].result[0].categories['best-practices'].score * 100).toFixed(0) : 0,
                    // lh_data:                [{audits: result[0].result[0].audits}],
                }

                // loop through the audits and see if the score is less than 0.8

                let audits = result[0].result[0].audits;

                let newAudits = {};

                Object.keys(audits).forEach((key) => {

                    console.log('key', key)
                    console.log('score', +audits[key].score)

                    if (+audits[key].score < 0.8) {
                        
                        console.log(audits[key])
                        newAudits[key] = audits[key];
                        
                    }

                })

                console.log('newAudits', newAudits)

                lhData.lh_data = [{audits: newAudits}];

                
                console.log("data", lhData)
                await saveLighthouse(lhData)

                resolve(lhData)
    
            }).catch((error) => {
    
                console.log(error)
                console.log('I found an error');
    
                reject("AXIOS error", error)
            });
    
        })
            
    }

    const fetchLatestLighthouse = async () => {

        try {

            let results = await raw(`

            SELECT l.lh_s, l.lh_b, l.lh_p, l.lh_a, l.lh_created, l.lh_target_name, l.lh_target_url, l.lh_id
            FROM lighthouse AS l
            INNER JOIN (
                SELECT lh_target_url, MAX(lh_created) AS latest
                FROM lighthouse
                WHERE lh_cm_id = "${campaign.cm_id}"
                GROUP BY lh_target_url
            ) AS latest_records ON l.lh_target_url = latest_records.lh_target_url AND l.lh_created = latest_records.latest
            WHERE l.lh_cm_id = "${campaign.cm_id}"
            
            `)


            return await unescapeNested(results);

        } catch (err) {

            return [];
        }
    };

    const fetchPageLighthouseLastSix = async (target_url) => {

        target_url = escape(target_url);


        try {

            let results = await raw(`

            SELECT l.lh_s, l.lh_b, l.lh_p, l.lh_a, l.lh_created, l.lh_target_name, l.lh_target_url, l.lh_id
            FROM lighthouse AS l
            WHERE lh_cm_id = "${campaign.cm_id}"
                AND lh_target_url = "${target_url}"
            ORDER BY lh_created ASC
            LIMIT 6
            `)


            return await unescapeNested(results);

        } catch (err) {

            return [];
        }
    };

    const fetchLighthouse = async (lh_id) => {
        try {

            let results = await get('lighthouse', {lh_id})

            return await unescapeNested(results[0]);

        } catch (err) {

            return [];
        }
    };

    const saveLighthouse = async (newItems, lh_id) => {

        newItems = await escapeNested(newItems);
        newItems = await stringifyArraysInObject(newItems)

        if (lh_id) {

            await save('lighthouse', newItems, {lh_id: lh_id})
        } else {
            lh_id = await save('lighthouse', newItems)
        }

        return lh_id

    }

    

    return { saveLighthouse, runLighthouseReport, fetchLatestLighthouse, fetchLighthouse, fetchPageLighthouseLastSix };
};


export { useLighthouse };
