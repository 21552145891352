import { get, save } from '../../Api/API';
import { useGlobal } from '../GlobalProvider';
import { escapeNested, stringifyArraysInObject, unescapeNested } from '../unescapeArray';

const useTasks = () => {

    const { campaign, UserData } = useGlobal();

    const fetchTasksByDate = async (from, to) => {
        try {

            let results = await get(
                'tasks', 
                {
                    'tk_due >= ': from,
                    'tk_due <= ': to,
                }, 
                {tk_due: 'asc'},
                ['tasks.*, campaigns.*, products.*'],
                [
                    {table: 'campaigns', condition: "campaigns.cm_id = tasks.tk_cm_id"},
                    {table: 'products', condition: "products.pr_id = tasks.tk_pr_id"},
                ]
                )

            return await unescapeNested(results);

        } catch (err) {

            return [];
        }
    };

    const fetchTasks = async () => {
        try {

            let results = await get(
                'tasks', 
                {tk_cm_id: campaign.cm_id}, 
                {tk_due: 'asc'},
                ['tasks.*, campaigns.*, products.*, packages.*'],
                [
                    {table: 'campaigns', condition: "campaigns.cm_id = tasks.tk_cm_id"},
                    {table: 'products', condition: "products.pr_id = tasks.tk_pr_id"},
                    {table: 'packages', condition: "packages.pk_id = tasks.tk_pk_id"}
                ]
                )

            return await unescapeNested(results);

        } catch (err) {

            return [];
        }
    };

    const fetchAdminTasks = async (status, from, to) => {

       
        let where = {
            'tk_due >=': from,
            'tk_due <=': to
        };
        if (status.toLowerCase() === "mine") {
            where['tk_us_id'] = UserData.us_id
        }
        
        try {

            let results = await get(
                'tasks',
                where,
                {tk_due: 'asc'},
                ['tasks.*, campaigns.*, products.*, users.us_first_name, users.us_last_name, lh_id'],
                [
                    {table: 'campaigns', condition: "campaigns.cm_id = tasks.tk_cm_id"},
                    {table: 'products', condition: "products.pr_id = tasks.tk_pr_id"},
                    {table: 'lighthouse', condition: "lighthouse.lh_cm_id = tasks.tk_cm_id"},
                    {table: 'users', condition: "users.us_id = tasks.tk_us_id"}
                ]
            )

            return await unescapeNested(results);

        } catch (err) {

            return [];
        }
    };

    const fetchTask = async (tk_id) => {

        try {

            let results = await get(
                'tasks', 
                {tk_id: tk_id}, 
                null,
                ['tasks.*, campaigns.*, products.*, packages.*, users.*'],
                [
                    {table: 'campaigns', condition: "campaigns.cm_id = tasks.tk_cm_id"},
                    {table: 'products', condition: "products.pr_id = tasks.tk_pr_id"},
                    {table: 'packages', condition: "packages.pk_id = tasks.tk_pk_id"},
                    {table: 'users', condition: "users.us_id = tasks.tk_us_id"}
                ]
                )

            return await unescapeNested(results[0]);

        } catch (err) {

            return [];
        }
    };

    const saveTask = async (newItems, tk_id) => {

        newItems = await escapeNested(newItems);
        newItems = await stringifyArraysInObject(newItems)

        console.log(newItems)

        if (tk_id) {

            await save('tasks', newItems, {tk_id: tk_id})
        } else {
            tk_id = await save('tasks', newItems)
        }

        return tk_id

    }

    

    return { fetchTasks, saveTask, fetchTask, fetchAdminTasks, fetchTasksByDate };
};


export { useTasks };
