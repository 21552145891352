import { useEffect, useState } from 'react';
import { get, save } from '../../Api/API';
import {  escapeNested, parseStringifiedArrays, stringifyArraysInObject, unescapeNested } from '../unescapeArray';

const useProducts = () => {

    const [ products, setProducts ] = useState([])

    useEffect( () => {

        fetchProducts()

    }, [])

    const fetchProducts = async () => {
        try {

            let results = await get('products', null, {pr_category: 'asc', pr_name: 'asc'})

            setProducts( await unescapeNested(results) );

        } catch (err) {

            setProducts([])
        }
    };

    const fetchProduct = async (pr_id) => {

        try {

            let results = await get('products', {pr_id}, {pr_priority: 'asc'})

            results = await parseStringifiedArrays(results);
            results = await unescapeNested(results);

            return results[0];
            

        } catch (err) {

            return ([])
        }
    };

    const saveProduct = async (newItems, pr_id) => {
        
        newItems = await escapeNested(newItems);
        newItems = await stringifyArraysInObject(newItems)

        if (pr_id) {
            await save('products', newItems, {pr_id})
        } else {
            pr_id = await save('products', newItems)
        }
        
        return pr_id

    };

    return { products, saveProduct, fetchProduct };
};


export { useProducts };
