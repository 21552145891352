import axios from 'axios';
import SiteConfig from '../SiteConfig';

let _endpoint = SiteConfig.endpoint;

export default function API () {

  return null
  
}

export const globalGet = (tableName, _where = null, _order = null, _fields = null, _joins = null, _group = null, _select = null) => {


  return new Promise((resolve, reject) => {

      let params = {};
      if (_where && typeof(_where) === "object")    { params.where = encodeURIComponent(JSON.stringify(_where)) }
      if (_order && typeof(_order) === "object")    { params.order = encodeURIComponent(JSON.stringify(_order)) }
      if (_group && typeof(_group) === "object")    { params.group = encodeURIComponent(JSON.stringify(_group)) }
      if (_select && _select.length > 0)            { params.select = encodeURIComponent(JSON.stringify(_select)) }
      if (_fields && typeof(_fields) === "object")  { params.fields = encodeURIComponent(JSON.stringify(_fields)) }
      if (_joins && typeof(_joins) === "object")    { params.joins = encodeURIComponent(JSON.stringify(_joins)) }

      axios.get(`${_endpoint}/key/${SiteConfig.apiKey}/${tableName}`, {
          params: params
        })
        .then(function (response) {
          
          console.log(response);
          resolve(response.data.result);
        })
        .catch(function (error) {
          //console.log(error)
          reject(error);
        });  
  })

}

export const getData = async (tableName, options = {}) => {
  try {
    const formattedParams = Object.keys(options).reduce((acc, key) => {
      if (options[key]) {
        acc[key] = encodeURIComponent(JSON.stringify(options[key]));
      }
      return acc;
    }, {});

    const response = await axios.get(`${_endpoint}/key/${SiteConfig.apiKey}/${tableName}`, {
      params: formattedParams
    });

    return response.data.result;
  } catch (error) {
    throw error;
  }
};

export const get = (tableName, _where = null, _order = null, _fields = null, _joins = null, _group = null, _select = null) => {

  return new Promise((resolve, reject) => {

      let params = {};
      if (_where && typeof(_where) === "object")    { params.where = encodeURIComponent(JSON.stringify(_where)) }
      if (_order && typeof(_order) === "object")    { params.order = encodeURIComponent(JSON.stringify(_order)) }
      if (_group && typeof(_group) === "object")    { params.group = encodeURIComponent(JSON.stringify(_group)) }
      if (_select && _select.length > 0)            { params.select = encodeURIComponent(JSON.stringify(_select)) }
      if (_fields && typeof(_fields) === "object")  { params.fields = encodeURIComponent(JSON.stringify(_fields)) }
      if (_joins && typeof(_joins) === "object")    { params.joins = encodeURIComponent(JSON.stringify(_joins)) }

      axios.get(`${_endpoint}/key/${SiteConfig.apiKey}/${tableName}`, {
          params: params
        })
        .then(function (response) {
          
          // console.log(response);
          resolve(response.data.result);
        })
        .catch(function (error) {
          // console.log(error)
          reject(error);
        });  
  })

}

export const getOld = (tableName, _where = null, _order = null, _fields = null, _joins = null, _group = null, _select = null) => {

  return new Promise((resolve, reject) => {

      let params = {};
      if (_where && typeof(_where) === "object")    { params.where = encodeURIComponent(JSON.stringify(_where)) }
      if (_order && typeof(_order) === "object")    { params.order = encodeURIComponent(JSON.stringify(_order)) }
      if (_group && typeof(_group) === "object")    { params.group = encodeURIComponent(JSON.stringify(_group)) }
      if (_select && _select.length > 0)            { params.select = encodeURIComponent(JSON.stringify(_select)) }
      if (_fields && typeof(_fields) === "object")  { params.fields = encodeURIComponent(JSON.stringify(_fields)) }
      if (_joins && typeof(_joins) === "object")    { params.joins = encodeURIComponent(JSON.stringify(_joins)) }

      axios.get(`${_endpoint}/key/${SiteConfig.apiKey}/old/${tableName}`, {
          params: params
        })
        .then(function (response) {
          
          // console.log(response);
          resolve(response.data.result);
        })
        .catch(function (error) {
          // console.log(error)
          reject(error);
        });  
  })

}

export const raw = (query) => {


  return new Promise((resolve, reject) => {

      let params = {};
      params.query = encodeURIComponent(query)

      axios.get(`${_endpoint}/key/${SiteConfig.apiKey}/raw`, {
          params: params
        })
        .then(function (response) {
          
          // console.log(response);
          resolve(response.data.result);
        })
        .catch(function (error) {
          //console.log(error)
          reject(error);
        });  
  })

}

export const save = (tableName, _data, _where) => {

  Object.keys(_data).forEach((key, val) => {
    
    if (!_data[key] || _data[key] === "") _data[key] = "NULL";
  })

  if (_where) { 

    return new Promise((resolve, reject) => {

        axios.put(`${_endpoint}/key/${SiteConfig.apiKey}/${tableName}`,
            {
                where: _where,
                data: _data
            })
          .then(function (response) {
           
            console.log(response);
            resolve(response.data.result);
          })
          .catch(function (error) {
            //console.log(error)
            reject(error);
          })
          .finally(function () {
            
                
          });  
    })
    
  } else {
    
    return new Promise((resolve, reject) => {

      axios.post(`${_endpoint}/key/${SiteConfig.apiKey}/${tableName}`, 
          _data)
        .then(function (response) {
          
          // console.log(response);
          resolve(response.data.result);
        })
        .catch(function (error) {
          // console.log(error)
          reject(error);
        })
        .finally(function () {
          
        });  
    })
    
  }

}

export const checkUrl = (url) => {

  
    return new Promise((resolve, reject) => {

      axios.post(`${_endpoint}/key/${SiteConfig.apiKey}/checkurl`, 
          {url})
        .then(function (response) {
          
          // console.log(response);
          resolve(response.data.data);
        })
        .catch(function (error) {
          // console.log(error)
          reject(error);
        })
        .finally(function () {
          
        });  
    })

}

export const remove = (tableName, _where) => {

  return new Promise((resolve, reject) => {

      let params = {};
      if (_where && typeof(_where) === "object")    { params.where = encodeURIComponent(JSON.stringify(_where)) }

      axios.delete(`${_endpoint}/key/${SiteConfig.apiKey}/${tableName}`,{
        params: params
      })
        .then(function (response) {
        
          // console.log(response);
          resolve(response.data.result);
        })
        .catch(function (error) {
          // console.log(error)
          reject(error);
        })
        .finally(function () {
          
              
        });  
  })

}

export const sendEmail = (toEmail, tl_website) => {

  return new Promise((resolve, reject) => {

    axios.post(`${_endpoint}/key/${SiteConfig.apiKey}/email`,
        {
            toEmail,
            tl_website,
        })
      .then(function (response) {
       
        console.log(response);
        resolve(response.data.result);
      })
      .catch(function (error) {
        // console.log(error)
        reject(error);
      })
      .finally(function () {
        
            
      });  
  })

}