import { Button, Card, Col, Row, Table } from "antd"
import { useGlobal } from "../../System/Global/GlobalProvider"
import { useLighthouse } from "../../System/Global/DataHooks/useLighthouse";
import { useEffect, useState } from "react";
import Running from "../../System/Components/Running";
import dayjs from 'dayjs'
import Loader from "../../System/Components/Loader";
import { useNavigate } from "react-router-dom";

export const LighthouseList = () => {

    const { campaign } = useGlobal();
    const { runLighthouseReport, fetchLatestLighthouse } = useLighthouse();
    const [ pages, setPages ] = useState([])
    const [ isRunning, setIsRunning ] = useState(false)
    const [ isLoading, setIsLoading ] = useState(true)
    const navigate = useNavigate();


    useEffect( () => {

        if (campaign) {

            loadInitialData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign])

    const loadInitialData = async () => {

        const newPages = [];
        const llh = await fetchLatestLighthouse();

        for await (let p of campaign.cm_target_pages) {

            let lh = llh.find((l) => l.lh_target_url === "https://" + p.url)

            let pData = {
                index:          p.index,
                target_name:    p.name,
                target_url:     p.url,
                ...lh,
            }

            newPages.push(pData)
            
        }

        setPages(newPages)

        

        setIsLoading(false)

    }

    const runReport = async ({target_name, target_url}) => {

        setIsRunning(true);
        console.log(target_name, target_url)
        await runLighthouseReport(target_name, "https://" + target_url)

        await loadInitialData();

        setIsRunning(false)

    }

    let cols = [
        {
            title: 'Name',
            render: item => `${item.target_name}`,
            key: 'pr_name',
        },
        {
            title: 'Category',
            render: item => `${item.target_url}`,
            key: 'pr_category',
        },
        {
            title: 'Last ran',
            render: item => item.lh_created && `${dayjs(item.lh_created).format('DD/MM/YYYY')}`,
            key: 'created',
        },
        {
            title: 'SEO',
            render: item => `${item.lh_s || "--"}`,
            key: 'SEO',
            width: 150,
            align: 'center',
        },
        {
            title: 'Performance',
            render: item => `${item.lh_p || "--"}`,
            key: 'performance',
            width: 150,
            align: 'center',
        },
        {
            title: 'Best Practice',
            render: item => `${item.lh_b || "--"}`,
            key: 'bestpractice',
            width: 150,
            align: 'center',
        },
        {
            title: 'Accessibility',
            render: item => `${item.lh_a || "--"}`,
            key: 'accessibility',
            width: 150,
            align: 'center',
        },
        {
            title: '',
            render: item => !item.lh_created || dayjs().subtract('10', 'days').isAfter(dayjs(item.lh_created)) ? <Button onClick={() => runReport(item)}>Run Lighthouse</Button> : "",
            key: 'action',
        },
        
    ]

    const onRowClick = (record) => {
        return {
            onClick: event => {

                const clickedCell = event.target.closest('td');

                const cellIndex = clickedCell ? clickedCell.cellIndex : -1;

                if (cellIndex < 7) {

                    if (record.lh_created) {
                        navigate('/lighthouse/view/' + record.lh_id)
                        return
                    } else {
                        return
                    }
                    
                }

            },
        };
    };

    return (
        isLoading ? <Loader/> :
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>Lighthouse</h1>
                </div>
            </div>
            <LighthouseDashboard pages={pages.filter((p) => p.lh_created)}/>
            { isRunning ? <Running/> :
            <Table 
                dataSource={pages} 
                rowKey="index" 
                columns={cols} 
                pagination={false}
                onRow={onRowClick}
            /> }
            {/* <Button onClick={runReport}>Run report</Button> */}
        </div>
    )

}

export default LighthouseList;

const LighthouseDashboard = ({pages}) => {

    const [ totals, setTotals ] = useState({s: 0, p: 0, b: 0, a: 0})

    useEffect( () => {

        ( async () => {

            // let s = 0;
            
            if (pages) {

                let s = pages.reduce((sum, p) => { return sum += +p.lh_s }, 0 )
                let p = pages.reduce((sum, p) => { return sum += +p.lh_p }, 0 )
                let b = pages.reduce((sum, p) => { return sum += +p.lh_b }, 0 )
                let a = pages.reduce((sum, p) => { return sum += +p.lh_a }, 0 )

                setTotals({
                    s: parseFloat(s / pages.length).toFixed(0),
                    p: parseFloat(p / pages.length).toFixed(0),
                    b: parseFloat(b / pages.length).toFixed(0),
                    a: parseFloat(a / pages.length).toFixed(0),
                })

            }

            

        })()


    }, [pages])

    const DbCard = ({title, score, description}) => {

        return (
            <Col span={6}>
                <Card hoverable className="dbCard">
                    <h3>{title}</h3>
                    <div className="dbStat">
                        {!isNaN(score) ? score : 0}
                    </div>
                    <div className="dbDesc">
                        {/* {description} */}
                    </div>
                </Card>
            </Col>
        )
    }

    return (
        <div>
            <Row gutter={16}>
                <DbCard title="SEO" score={totals.s} description={"SEO"}/>
                <DbCard title="Performance" score={totals.p} description={"SEO"}/>
                <DbCard title="Best Practice" score={totals.b} description={"SEO"}/>
                <DbCard title="Accessibility" score={totals.a} description={"SEO"}/>
            </Row>
        </div>
    )

}