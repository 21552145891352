import { LockOutlined } from "@ant-design/icons"
import { Card, Col, Row, Tag, Tooltip } from "antd"
import { FaCircleCheck } from "react-icons/fa6"
import { useNavigate, useLocation } from "react-router-dom"
import { getMasonicProvinces } from "../../Components/SiteWide/getMasonicProvinces"
import dayjs from 'dayjs';

export const SearchResults = () => {

    const location = useLocation();

    const results = location.state?.results;
    const query = location.state?.query;

    return (
        <div>
            <h1>Results for {query}...</h1>
            { results?.members?.length > 0 &&
            <>
            <h2>Members</h2>
            <div className="memberContainerSmall">
            { results?.members?.map((m, i) => {

                return (
                    <MemberCard m={m} key={i} /> 
                )
            })}
            </div>
            </>}

            { results?.groups?.length > 0 &&
            <>
            <h2>Groups</h2>
            <Row gutter={16}>
            { results?.groups?.map((g, i) => {

                return (
                    <GroupCard group={g} key={i} /> 
                )
            })}
            </Row>
            </>}

            { results?.lodges?.length > 0 &&
            <>
            <h2>Lodges</h2>
            <Row gutter={16}>
            { results?.lodges?.map((l, i) => {

                return (
                    <LodgeCard lodge={l} key={i} /> 
                )
            })}
            </Row>
            </>}

            { results?.events?.length > 0 &&
            <>
            <h2>Events</h2>
            <div className="eventContainerSmall">
            { results?.events?.map((e, i) => {

                return (
                    <EventCard event={e} key={i} /> 
                )
            })}
            </div>
            </>}

            { results?.businesses?.length > 0 &&
            <>
            <h2>Businesses</h2>
            <Row gutter={16}>
            { results?.businesses?.map((b, i) => {

                return (
                    <BusinessCard business={b} key={i} /> 
                )
            })}
            </Row>
            </>}

        </div>
    )

}

export default SearchResults;

const MemberCard = ({m}) => {

    const navigate = useNavigate();
    const provinces = getMasonicProvinces();

    return (
      
        <div style={{ display: 'flex' }}>
          <Card
              hoverable
              style={{ marginBottom: 16 }}
              cover={<img onClick={() => navigate(`/members/${m.us_id}`)} alt="example" src={m.us_image} />}
          >
              <Card.Meta 
                onClick={() => navigate(`/members/${m.us_id}`)} 
                title={
                  <div style={{fontSize: 13, display: 'flex', alignItems: "center"}}>
                    {m.us_first_name} {m.us_last_name} { m.us_verified ? <Tooltip title="Verified"><FaCircleCheck style={{marginLeft: 5}} size={20} color="#d66b39"/></Tooltip> : "" } 
                  </div>} 
                description={<div style={{fontSize: 12}}>{provinces[m.us_province]}</div>} />
          </Card>
      </div> 
      
       )
}
const GroupCard = ({ group }) => {

    const navigate = useNavigate()

    return (
     <Col span={4} style={{ display: 'flex' }}> {/* Add display: flex */}
      <Card
       style={{width: '100%', margin: '16px 0', position: 'relative'}}
        hoverable
        cover={
          <div style={{ height: '150px', overflow: 'hidden', display: 'flex', alignItems: 'flex-start' }}>
            
            { group.gr_image ?
            <img 
              alt={unescape(group.gr_title)} 
              src={group.gr_image} 
              style={{ 
                width: '100%', 
                height: 'auto'
              }} 
            />
            : 
            <div style={{backgroundColor: '#808080', borderRadius: '8px 8px 0 0', width: '100%', height: '100%'}}></div>
             }
          </div>
        }
        onClick={() => navigate(`/groups/${group.gr_id}`) }
       //  style={{ margin: '16px 0', position: 'relative' }}  // Added position relative to the Card style
      >
        {group.gr_private ? (
          <LockOutlined 
            style={{ 
              position: 'absolute', 
              bottom: '20px', 
              right: '10px', 
              fontSize: '20px', 
              color: '#000' 
            }} 
          />
        ) : null}
        <Card.Meta title={unescape(group.gr_title)} description={`${unescape(group?.gr_description?.substring(0, 40))}${group.gr_description.length > 50 ? "..." : ""}`} />
      </Card>
    </Col>
    )
};
const LodgeCard = ({ lodge }) => {

    const navigate = useNavigate()
    const provinces = getMasonicProvinces();

    return (
     <Col span={4} style={{ display: 'flex' }}> {/* Add display: flex */}
      <Card
        hoverable
        cover={
          <div style={{ height: '150px', overflow: 'hidden', display: 'flex', alignItems: 'flex-start' }}>
            
            { lodge.lg_image ?
            <img 
              alt={unescape(lodge.lg_title)} 
              src={lodge.lg_image} 
              style={{ 
                width: '100%', 
                height: 'auto'
              }} 
            />
            : 
            <div style={{backgroundColor: '#808080', borderRadius: '8px 8px 0 0', width: '100%', height: '100%'}}></div>
             }
          </div>
        }
        onClick={() => navigate(`/lodges/${lodge.lg_id}`) }
        style={{ margin: '16px 0', position: 'relative', width: '100%', }}  // Added position relative to the Card style
      >
        <Card.Meta title={
         <div>
           {lodge.lg_number}<br/>
           {unescape(lodge.lg_title)}<br/>
         </div>
         // `(${lodge.lg_number}) ${lodge.lg_title.substring(0, 20)}`
         } description={`${provinces[lodge.lg_province]}`} />
      </Card>
    </Col>
    )
};
const EventCard = ({ event }) => {

    const navigate = useNavigate();
    
    return (
     
     <div style={{ display: 'flex' }}>
       <Card
           hoverable
           onClick={() => navigate(`/events/${event.ev_id}`)}
           style={{ marginBottom: 16 }} 
           cover={<img alt="example" src={event ? event.ev_image : null} />}
       > 
           {/* <Card.Meta title={`${event.ev_title}`} description={event.ev_description} /> */}
           <h4 style={{color: '#F3425E', margin: 0, display: 'flex', alignItems: 'center'}}>
               {dayjs(event.ev_date).format('ddd Do MMM \'YY')} at {event.ev_time}
           </h4>
           <h3 style={{marginTop: 10, marginBottom: 0, display: 'flex', alignItems: 'center'}}>
               {event.ev_title}
           </h3>
           <p style={{marginTop: 5}}>
             {event.ev_description?.substring(0, 80)}{event.ev_description?.length > 80 ? "..." : ""}
           </p>
           <Row gutter={16}>
             <Col span={12}>
               <div style={{marginTop: 15}}>
                 <Tag>{event.ev_category}</Tag>
                 </div>
               </Col>
           </Row>
           
       </Card>
   </div>
   
    )
};
const BusinessCard = ({ business }) => {

    const navigate = useNavigate()

    return (
     <Col span={4} style={{ display: 'flex' }}> {/* Add display: flex */}
      <Card
       style={{width: '100%', margin: '16px 0', position: 'relative'}}
        hoverable
        cover={
          <div style={{ height: '150px', overflow: 'hidden', display: 'flex', alignItems: 'flex-start' }}>
            
            { business.bs_image ?
            <img 
              alt={unescape(business.bs_title)} 
              src={business.bs_image} 
              style={{ 
                width: '100%', 
                height: 'auto'
              }} 
            />
            : 
            <div style={{backgroundColor: '#808080', borderRadius: '8px 8px 0 0', width: '100%', height: '100%'}}></div>
             }
          </div>
        }
        onClick={() => navigate(`/businesses/${business.bs_id}`) }
       //  style={{ margin: '16px 0', position: 'relative' }}  // Added position relative to the Card style
      >
        
       <Card.Meta 
         title={unescape(business.bs_title)} 
         description={
           <div>
             <p>
             {unescape(business?.bs_description?.substring(0, 40))}{business.bs_description.length > 50 ? "..." : ""}
             </p>
             <div style={{width: '100%', backgroundColor: '#f1f1f1', textAlign: 'center', padding: 5}}>
               {business.bs_category}
             </div>
           </div>}
       />
      </Card>
    </Col>
    )
};