import React, {useState} from 'react';
import { Form, Input, Button, Card, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useGlobal } from '../../System/Global/GlobalProvider';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {

    const { authenticate } = useGlobal();
    const navigate = useNavigate();
    const [error, setError] = useState(null);


    const onFinish = async (values) => {
        
        const loginSuccess = await authenticate(values.username, values.password);

        if(loginSuccess) {
            
            navigate('/campaigns/list')

        } else {

            console.log(loginSuccess)

            setError("Invalid username or password.");
        }

        // Handle authentication logic here
    };

    return (
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: '#f5f5f5' }}>
            <Card style={{ width: '500px' }}>
                <div style={{textAlign: 'center'}}>
                    {/* <img style={{ width: 80, marginTop: 15 }} src="/images/Yell-Agency-Logo.webp" alt="sac"/> */}
                    <h2 style={{margin: 0}}>Login</h2>
                    {error && <Alert message={error} type="error" showIcon style={{ margin: 15 }} />}
                </div>
                <Form
                    name="login_form"
                    layout="vertical"
                    onFinish={onFinish}
                    style={{ background: '#fff', padding: '24px', borderRadius: '8px' }}
                >
                    <Form.Item
                        label="Email"
                        name="username"
                        rules={[{ type: 'email', message: 'The input is not valid email!' }]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Username" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                    </Form.Item>

                    <Form.Item>
                        <Button style={{height: 45}} type="primary" htmlType="submit" block>
                            Log in
                        </Button>
                    </Form.Item>
                    {/* <Form.Item style={{ textAlign: 'center' }}>
                         Don't have an account? <Link to="/signup">Signup</Link>
                    </Form.Item> */}
                </Form>
            </Card>
        </div>
    );
}

export default LoginPage;
