import { Route, Routes } from 'react-router-dom';
import CampaignList from '../../Components/Campaigns/CampaignList';
import { FiSettings } from 'react-icons/fi';
import AddCampaign from '../../Components/Campaigns/AddCampaign';
import ViewCampaign from '../../Components/Campaigns/ViewCampaign';
import { AdminCampaignList } from '../../Components/Campaigns/AdminCampaignList';
import Emails from '../../Components/Campaigns/Emails/Emails';
import { AdminViewCampaign } from '../../Components/Campaigns/AdminViewCampaign';

export const campaignMenu = () => ( {
     key: "2",
     link: "/campaign/view",
     label: 'Campaign brief',
     icon: <FiSettings/>,
     
})

export const campaignRoutes = () => (
     <Routes> 
          <Route path="campaigns/admin/list/:status" element={<AdminCampaignList />} />
          <Route path="campaigns/list" element={<CampaignList />} />
          <Route path="campaign/admin" element={<AdminViewCampaign />} />
          <Route path="campaigns/add" element={<AddCampaign />} />
          <Route path="campaign/view" element={<ViewCampaign />} />
          <Route path="email" element={<Emails />} />
     </Routes>
)