import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useProducts } from '../../System/Global/DataHooks/useProducts';

export const AddProduct = () => {

    const navigate = useNavigate();
    const [ form ] = Form.useForm();
    const { saveProduct, fetchProduct } = useProducts();
    const { prid } = useParams();
    const [ product, setProduct ] = useState()

    useEffect( () => {

        ( async () => {

            if (prid) {

                let _p = await fetchProduct(prid)
                setProduct(_p)
                form.setFieldsValue(_p)
            }

        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prid])

    const saveProductDetails = async (values) => {

        let data = {
            ...values, 
        }

        if (prid) {

            await saveProduct(data, prid);

        } else {

            await saveProduct(data);
        }
        

        navigate(`/products/list`)
        
    }

    return (
        <>
        <h1>{product ? "Edit Product" : "New Product"}</h1>
            <Form onFinish={saveProductDetails} form={form} layout="vertical" initialValues={{pr_level: "Page", pr_category: "Content"}}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Card style={{marginBottom: 30}}>
                            <Form.Item name="pr_name" label="Product name" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a product name!',
                                }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="pr_category" label="Category">
                                <Select>
                                    <Select.Option value={"Content"}>Content</Select.Option>
                                    <Select.Option value={"Technical"}>Technical</Select.Option>
                                    <Select.Option value={"CRO-UX"}>CRO/UX</Select.Option>
                                    <Select.Option value={"Admin"}>Admin</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="pr_minutes" label="Minutes" rules={[
                                {
                                    required: true,
                                    message: 'Please enter a number of minutes!',
                                }]}>
                                <Input type="number" />
                            </Form.Item>
                            <Form.Item name="pr_level" label="Level">
                                <Select>
                                    <Select.Option value={"Page"}>Page</Select.Option>
                                    <Select.Option value={"Site"}>Site</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item style={{textAlign: 'right'}}>
                                <Button style={{marginRight: 5}} onClick={() => navigate('/products/list')} htmlType='button'>Cancel</Button>
                                <Button type="primary" htmlType='submit'>Submit</Button>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default AddProduct;