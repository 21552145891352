import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Input, Table } from 'antd';
import dayjs from 'dayjs';
import { useCampaigns } from '../../System/Global/DataHooks/useCampaigns';
import { useGlobal } from '../../System/Global/GlobalProvider';
import SiteConfig from '../../System/SiteConfig';
import { get } from '../../System/Api/API';

export const AdminCampaignList = () => {

    const { status } = useParams();
    const { setCampaign } = useGlobal();
    const { fetchCampaigns } = useCampaigns();
    const [ campaigns, setCampaigns ] = useState()
    const navigate = useNavigate();
    const [ header, setHeader ] = useState("Campaigns")
    const [ filteredCampaigns, setFilteredCampaigns ] = useState([])
    const [ searchText, setSearchText ] = useState("")
    
    useEffect( () => {

        ( async () => {

            
            
                let _campaigns = [];
                _campaigns = await fetchCampaigns()

                if (status === "active") {
                    _campaigns = _campaigns.filter((c) => c.cm_onboarded === 1 && c.cm_status === "Live")

                    console.log('campaigns', _campaigns)
                    setHeader("Active Campaigns")
                }

                if (status === "pending") {
                    _campaigns = _campaigns.filter((c) => c.cm_onboarded === 0)
                    setHeader("Pending Campaigns")
                }

                if (status === "paused") {
                    _campaigns = _campaigns.filter((c) => c.cm_status === "Paused")
                    setHeader("Paused Campaigns")
                }

                if (status === "cancelled") {
                    _campaigns = _campaigns.filter((c) => c.cm_status === "Cancelled")
                    setHeader("Cancelled Campaigns")
                }
                
                
                setCampaigns(_campaigns)
                setFilteredCampaigns(_campaigns)
                
            
        })()

        return () => {
            setCampaigns([])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect( () => {

        if (campaigns) {
            setFilteredCampaigns(campaigns.filter((c) => c.cm_name.toLowerCase().includes(searchText.toLowerCase())))
        }

    }, [searchText])

    let cols = [
        {
            title: 'Name',
            render: item => `${item.cm_name}`,
            key: 'cm_name',
        },
        {
            title: 'Service Id',
            render: item => `${item.cm_service_id}`,
            key: 'cm_service_id',
        },
        {
            title: 'URL',
            render: item => `${item.cm_url}`,
            key: 'cm_url',
        },
        {
            title: 'Created',
            key: 'date',
            render: item => dayjs(item.cm_created).format('DD/MM/YYYY')
        },
        {
            title: 'SAP ID',
            render: item => `${item.cm_sap_id}`,
            key: 'cm_sap_id',
        },
        {
            title: 'Package',
            render: item => `${item.pk_name}`,
            key: 'cm_pk_id',
        },
        
    ]


    const onRowClick = (record) => {
        return {
            onClick: event => {

                // select campign

                localStorage.setItem(`${SiteConfig.cookieName}-cm_id`, record.cm_id);
                setCampaign(record);

                if (record.cm_onboarded) {
                    navigate('/lighthouse/list')
                } else {
                    navigate('/campaign/view')
                }
                
            },
        };
    };

    
   
    return (
        campaigns &&
        <div>
            <div style={{display: 'flex', alignItems: "center"}}>
                <div style={{flex: 1}}>
                    <h1 style={{marginTop: 5}}>{header}</h1>
                </div>
                <div>
                    <Button onClick={() => navigate('/campaigns/add')} type="primary">New campaign</Button>
                </div>
            </div>
            <div>
                <Card>
                <Input value={searchText} onChange={(v) => setSearchText(v.target.value) } placeholder="Search by campaign name"/>
                </Card>
                <br/>
            </div>
            <Table 
                dataSource={filteredCampaigns} 
                rowKey="cm_id" 
                columns={cols} 
                pagination={false}
                onRow={onRowClick}
            />
        </div>
    )
}

export default AdminCampaignList;