import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Row, Select, notification } from 'antd';
import { useCampaigns } from '../../../System/Global/DataHooks/useCampaigns';
import { usePackages } from '../../../System/Global/DataHooks/usePackages';
import { useGlobal } from '../../../System/Global/GlobalProvider';
import SiteConfig from '../../../System/SiteConfig';
import { checkUrl } from '../../../System/Api/API';
import TextArea from 'antd/es/input/TextArea';

export const CampaignDetails = () => {

    const navigate = useNavigate();
    const [ form ] = Form.useForm();
    const { saveCampaign } = useCampaigns();
    const { packages } = usePackages();    
    
    const { setCampaign, campaign, UserData } = useGlobal();

    const saveCampaignDetails = async (values) => {

        let data = {
            ...values
        }

        if (values.cm_onboarded === 0) {
            data.cm_onboarded = "0"
        }

        let cm_id = await saveCampaign(data, campaign.cm_id);

        localStorage.setItem(`${SiteConfig.cookieName}-cm_id`, cm_id);

        setCampaign({...campaign, cm_id, ...data})

        notification.success({ type: "success", message: "You campaign has been updated"})

        
    }

    
    const validateUrl = async (_, url) => {
        if (!url) {
            // If the URL is empty, you might want to allow it or show a different message
            return Promise.reject(new Error('URL is required'));
        }
    
        try {
            const responseStatus = await checkUrl('https://' + url);
            if (responseStatus === 200) {
                // If the URL is valid
                return Promise.resolve();
            } else {
                // If the URL is not valid
                return Promise.reject(new Error('Please enter a valid URL'));
            }
        } catch (error) {
            // If there's an error (e.g., network error), you can handle it accordingly
            return Promise.reject(new Error('URL validation failed'));
        }
    };
    

    
    return (
        packages?.length > 0 &&
        <>
            <Form onFinish={saveCampaignDetails} form={form} layout="vertical" initialValues={campaign}>
                <Row gutter={16}>
                    <Col span={12}>
                        
                        <Card style={{marginBottom: 30}}>
                        <Form.Item name="cm_name" label="Campaign name" rules={[
                            {
                                required: true,
                                message: 'Please enter a campaign name!',
                            }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="cm_performance" label="Performance">
                            <Select>
                                <Select.Option value="Under Performing">Under Performing</Select.Option>
                                <Select.Option value="Average Performance">Average Performance</Select.Option>
                                <Select.Option value="Over Performing">Over Performing</Select.Option>
                            </Select>
                        </Form.Item>
                    
                        <Form.Item name="cm_url" label="Website URL" rules={[
                            {
                                required: true,
                                validator: validateUrl,
                            }]}
                            validateTrigger='onBlur'
                            >
                                
                            <Input />
                        </Form.Item>

                        <Form.Item name="cm_sap_id" label="Thomson Ref" rules={[
                            {
                                required: true,
                                message: 'Please enter a SAP ID!',
                            }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="cm_service_id" label="Service ID" rules={[
                            {
                                required: true,
                                message: 'Please enter a Service ID!',
                            }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="cm_order_no" label="Order No" rules={[
                            {
                                required: true,
                                message: 'Please enter a Order No!',
                            }]}>
                            <Input />
                        </Form.Item>
                        { UserData.us_role === "Admin" &&
                        <Form.Item name="cm_notes" label="Notes">
                            <TextArea rows={5} />
                        </Form.Item>}
                    
                        

                        </Card>
                    </Col>

                    <Col span={12}>
                        
                        <Card style={{marginBottom: 30}}>
                        <Form.Item name="cm_pk_id" label="Package">
                            <Select>
                                { packages?.map((p) => <Select.Option key={p.pk_id} value={p.pk_id}>{p.pk_name} ({p.pk_pages} target pages)</Select.Option> )}
                            </Select>
                        </Form.Item>

                        { campaign?.cm_target_pages?.length > 0 &&
                        <Form.Item name="cm_onboarded" label="Onboarded">
                            <Select>
                                <Select.Option value={0}>No</Select.Option>
                                <Select.Option value={1}>Yes</Select.Option>
                            </Select>
                        </Form.Item> }
                        { UserData.us_role === "Admin" ?
                        <Form.Item label="Keywords" name="cm_keywords">
                            
                            <Input.TextArea rows={5} />
                            
                        </Form.Item>
                        :
                        <Form.Item label="Keywords">
                            { campaign.cm_keywords.split(',').map((k) => <p>{k}</p>) }
                        </Form.Item> }

                        <Form.Item name="cm_description" label="Business description" rules={[
                            {
                                required: true,
                                message: 'Please enter a description!',
                            }]}>
                            <Input.TextArea rows={5} />
                        </Form.Item>

                        <Form.Item style={{textAlign: 'right'}}>
                            <Button style={{marginRight: 5}} onClick={() => navigate('/campaigns/list')} htmlType='button'>Cancel</Button>
                            <Button type="primary" htmlType='submit'>Submit</Button>
                        </Form.Item>

                        </Card>
                    </Col>
                    
                </Row>
                
            </Form>
            
        
        
        </>
    )
}

export default CampaignDetails;