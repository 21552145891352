import { Route, Routes } from 'react-router-dom';
import CampaignList from '../../Components/Campaigns/CampaignList';
import { FiSettings } from 'react-icons/fi';
import AddCampaign from '../../Components/Campaigns/AddCampaign';
import ViewCampaign from '../../Components/Campaigns/ViewCampaign';
import { AdminCampaignList } from '../../Components/Campaigns/AdminCampaignList';
import Emails from '../../Components/Campaigns/Emails/Emails';
import InvoiceList from '../../Components/Invoicing/InvoiceList';
import AddInvoice from '../../Components/Invoicing/AddInvoice';

export const billingMenu = () => ( {
     key: "8",
     link: "/billing",
     label: 'Billing',
     icon: <FiSettings/>,
     
})

export const billingRoutes = () => (
     <Routes> 
          <Route path="billing" element={<InvoiceList />} />
          <Route path="billing/add" element={<AddInvoice />} />
          <Route path="billing/:invid" element={<AddInvoice />} />
          
     </Routes>
)