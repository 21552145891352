import { Route, Routes } from 'react-router-dom';
import { FiPlus, FiStar, FiUsers } from 'react-icons/fi';
import UsersList from '../../Components/Users/UsersList';
import ViewUser from '../../Components/Users/ViewUser';
import AddUser from '../../Components/Users/AddUser';

export const UsersMenu = () => ( {
     key: "10",
     link: "/users/list/admin",
     label: 'Users',
     icon: <FiUsers/>,
     innerMenu: [
          {
               key: "10.1",
               title: "Users"
          },
          {
               key: "10.2",
               link: "/users/add",
               label: 'New User',
               icon: <FiPlus/>,
          },
          {
               key: "10.3",
               link: "/users/list/admin",
               label: 'Admin Users',
               icon: <FiStar/>,
          },
          {
               key: "10.4",
               link: "/users/list/thomson",
               label: 'Thomson Users',
               icon: <FiUsers/>,
          }
     ]
     
})

export const usersRoutes = () => (
     <Routes> 
          <Route path="users/list/:status" element={<UsersList />} />
          <Route path="users/view/:usId" element={<ViewUser />} />
          <Route path="users/add" element={<AddUser />} />
     </Routes>
)