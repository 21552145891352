import { Route, Routes } from 'react-router-dom';
import { FiCodesandbox, FiPlus } from 'react-icons/fi';
import PackageList from '../../Components/Packages/PackageList';
import AddPackage from '../../Components/Packages/AddPackage';

export const PackageMenu = () => ( {
     key: "7",
     link: "/packages/list",
     label: 'Packages',
     icon: <FiCodesandbox/>,
     innerMenu: [
          {
               key: "7.1",
               title: "Packages"
          },
          {
               key: "7.2",
               link: "/packages/add",
               label: 'New Package',
               icon: <FiPlus/>,
          },
          {
               key: "7.3",
               link: "/packages/list",
               label: 'Packages',
               icon: <FiCodesandbox/>,
          }
     ]
     
})

export const packageRoutes = () => (
     <Routes> 
          <Route path="packages/list" element={<PackageList />} />
          <Route path="packages/add" element={<AddPackage />} />
          <Route path="packages/view/:pkid" element={<AddPackage />} />
     </Routes>
)