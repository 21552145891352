import { useEffect, useState } from 'react';
import { get, save } from '../../Api/API';
import {  escapeNested, parseStringifiedArrays, stringifyArraysInObject, unescapeNested } from '../unescapeArray';

const usePackages = () => {

    const [ packages, setPackages ] = useState([])

    useEffect( () => {

        fetchPackages()

    }, [])

    const fetchPackages = async () => {
        try {

            let results = await get('packages', null, {pk_price: 'asc'})

            setPackages( await unescapeNested(results) );

        } catch (err) { 

            setPackages([])
        }
    };

    const fetchPackage = async (pk_id) => {
        
        let results = await get('packages', {pk_id}, {pk_name: 'asc'})

        results = await parseStringifiedArrays(results);
        results = await unescapeNested(results);

        return results[0];

    };

    const savePackage = async (newItems, pk_id) => {
        
        newItems = await escapeNested(newItems);
        newItems = await stringifyArraysInObject(newItems)

        if (pk_id) {
            await save('packages', newItems, {pk_id})
        } else {
            pk_id = await save('packages', newItems)
        }
        
        return pk_id

    };
    

    return { packages, fetchPackage, savePackage };
};


export { usePackages };
