import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';

const Running = () => {
    const [percent, setPercent] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setPercent((prevPercent) => (prevPercent + 6 <= 100 ? prevPercent + 6 : 100));
        }, 2000);

        // Cleanup function to clear the interval
        return () => clearInterval(interval);
    }, []);

    const renderText = () => {
        if (percent < 20) {
            return "Running reports";
        } else if (percent < 40) {
            return "Checking site performance";
        } else if (percent < 60) {
            return "Checking optimisation";
        } else if (percent < 80) {
            return "Checking accessibility";
        } else if (percent < 100) {
            return "Checking best practices";
        } else {
            return "Finishing up";
        }
    };

    const renderSubText = () => {
        if (percent < 100) {
            return "This can take up to 30 seconds";
        } else {
            return "Sorry, just a few more bits";
        }
    };

    // #52449D

    return (
        <div className="running">
            <div className="icon">
                <Progress strokeColor={"#FFD100"} trailColor='#fff' type="circle" percent={percent} />
            </div>
            <div>
                <div className="text">{renderText()}</div>
                <div style={{ fontSize: 12 }}>{renderSubText()}</div>
            </div>
        </div>
    );
};

export default Running;
