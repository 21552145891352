import { get, raw, save } from '../../Api/API';
import { escapeNested, stringifyArraysInObject, unescapeNested } from '../unescapeArray';

const useCampaigns = () => {

    const fetchCampaigns = async () => {
        try {

            let results = await get(
                'campaigns', 
                null, 
                {cm_name: 'asc'},
                ['campaigns.*, packages.*'],
                [{table: "packages", condition: "packages.pk_id = campaigns.cm_pk_id"}]
            )

            return await unescapeNested(results);

        } catch (err) {

            return [];
        }
    };

    const fetchCampaign = async (cm_id) => {
        try {

            let results = await get('campaigns', {cm_id}, {cm_name: 'asc'})

            return await unescapeNested(results[0]);

        } catch (err) {

            return [];
        }
    };

    const saveCampaign = async (newItems, cm_id) => {

        newItems = await escapeNested(newItems);
        newItems = await stringifyArraysInObject(newItems)

        if (cm_id) {

            await save('campaigns', newItems, {cm_id: cm_id})
        } else {
            cm_id = await save('campaigns', newItems)
        }

        return cm_id

    }

    const findCampaigns = async (start, end) => {

        try {

            let results = await raw(`
                SELECT DISTINCT(tk_cm_id) AS tk_cm_id, cm_name, pk_name FROM tasks 
                LEFT OUTER JOIN campaigns ON tk_cm_id = cm_id
                LEFT OUTER JOIN packages ON cm_pk_id = pk_id
                WHERE tk_due >= "${start}" AND tk_due <= "${end}"
            `)

            results = await unescapeNested(results)

            results = results.sort((a, b) => {
                if (a.pk_name < b.pk_name) { return -1 } else if (b.pk_name < a.pk_name) { return 1 }
            });

            for await (let r of results) {

                if (r.pk_name === "SEO") { r.price = 26 }
                if (r.pk_name === "Starter") { r.price = 32.03 }
                if (r.pk_name === "Intermediate") { r.price = 47 }
                if (r.pk_name === "Premium") { r.price = 71 }
                if (r.pk_name === "Multi-page") { r.price = 46 }
            }

            return results

        } catch (err) {

            return [];
        }
    };

    return { fetchCampaigns, saveCampaign, fetchCampaign, findCampaigns };
};


export { useCampaigns };
