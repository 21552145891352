import { Route, Routes } from 'react-router-dom';
import { FiZap } from 'react-icons/fi';
import LighthouseList from '../../Components/Lighthouse/LighthouseList';
import LighthouseView from '../../Components/Lighthouse/LighthouseView';

export const lighthouseMenu = () => ( {
     key: "9",
     link: "/lighthouse/list",
     label: 'Lighthouse',
     icon: <FiZap/>,
     
})

export const lighthouseRoutes = () => (
     <Routes> 
          <Route path="lighthouse/list" element={<LighthouseList />} />
          <Route path="lighthouse/view/:lhid" element={<LighthouseView />} />
     </Routes>
)